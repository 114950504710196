import _ from "lodash";
import FirebaseApi from "../../api/FirebaseApi";
import FormOptions from "../../data/FormOptions";
export default {
  namespaced: true,
  state: () => ({
    // This determine what table to show (either path or region table)
    tableType: "path",
    studies: [],
  }),
  mutations: {
    tableType(state, type) {
      state.tableType = type;
    },
    studies(state, studies) {
      state.studies = studies;
    },
  },
  _getters: {
    /**
    /**
     * This method returns the data for the given table type.
     * It may change the presentation of some fields to make the values more readable. For instance, we know numAnimals
     * contains a range value. But it is stored as [x,y] in the database. We will present it as "x to y";
     * @returns {Array} array of table rows
     */
    rows(state) {
      return _.chain(state.studies)
        .filter((row) => {

          // If table type is region but this row contains targetTo (which should only
          // be available in path data, exclude this row
          if (state.tableType == "region" && row.targetTo != null)
            return false;

          // If this is a path table but targetTo doesn't exist, return false
          if (state.tableType == "path" && row.targetTo == null)
            return false;

          return true;
        })
        .value();
    },

    columns(state) {

      return _.chain(FormOptions.formFields).
        filter((column) => {
          if (state.tableType === "region" && column.code == "targetTo")
            return false;

          return true;
        }).map((formField) => {
          return {
            label: formField.name,
            field: formField.code,
            sortable: true,
          };
        }).
        value();

    },
  },
  get getters() {
    return this._getters;
  },
  set getters(value) {
    this._getters = value;
  },
  actions: {
    setTableType({ commit }, type) {
      if (!["path", "region"].includes(type)) {
        throw Error(`${type} is not a valid table type`);
      }
      commit("tableType", type);
    },
    async loadStudies(context) {
      const studies = await FirebaseApi.getStudies();
      context.commit("studies", studies);
    },
  },
};
