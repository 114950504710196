/**
 * This file contains constants used on various parts
 * of this codebase. These constants often represent
 * the name of the collection, document or a field
 * in the firestore database. Hardcoding them throughout
 * the codebase creates a serious risk of breakage in case
 * of refactor or mistype.
 *
 *
 */

/**
 * These are related application constants (terminologies
 * used for business purpose)
 */
export const TARGET_FROM = "targetFrom";
export const TARGET_TO = "targetTo";
export const STUDY_TYPE = "studyType";
// Name and code of brain regions
export const REGION_NAME = "name";
export const REGION_CODE = "code";

// regions of the brain
export const REGIONS = "regions";

/**
 * These are database constants that will
 * help idenfity collection, document
 * or fields in the firestore database
 */
export const APP_DATA = "appData";
export const STUDIES = "studies";
export const BRAIN_HIERARCHY = "brainHierarchy";
