import XLSX from "xlsx";
import FirebaseApi from "../api/FirebaseApi";
import FormOptions from "./FormOptions";

class XlsxExport {

    /**
* This method generates a xlsx file from the firebase data and returns
* the path to the file
* @returns {string} path to the file
*/
    static async export() {
        const workbook = XLSX.utils.book_new();

        const studies = await FirebaseApi.getStudies();


        // The following arrays are 2d arrays (array of arrays) that contains
        // worksheet data for path and region data
        const pathAoa = [];
        const regionAoa = [];

        // Notice the <=. This is because the first row is always going
        // to be the column name
        for (let row = 0; row <= studies.length; row += 1) {

            const pathRow = [];
            const regionRow = [];

            for (let col = 0; col < FormOptions.formFields.length; col += 1) {
                const field = FormOptions.formFields[col];
                // First row should be column name
                if (row == 0) {
                    pathRow.push(field.name);
                    regionRow.push(field.name);
                }
                else {
                    // Remember, first row is the field name. So, always
                    // read the study an index back
                    const study = studies[row - 1];


                    // Add the path or region data to appropriate arrays
                    if (study["studyType"] == "region") {
                        regionRow.push(study[field.code])
                    }
                    else {
                        pathRow.push(study[field.code])
                    }
                }
            }

            if (pathRow.length != 0) {
                pathAoa.push(pathRow);
            }
            if (regionRow.length != 0) {
                regionAoa.push(regionRow);
            }
        }

        const pathWorksheet = XLSX.utils.aoa_to_sheet(pathAoa, {
            sheetStubs: true,
        });
        const regionWorksheet = XLSX.utils.aoa_to_sheet(regionAoa, {
            sheetStubs: true,
        });

        XLSX.utils.book_append_sheet(workbook, pathWorksheet, "path");
        XLSX.utils.book_append_sheet(workbook, regionWorksheet, "region");


        XLSX.writeFile(workbook, "export.csv", {
            bookType: "csv",
            type: "file",
            cellStyles: true,
            sheetStubs: true,
            Props: { Title: "Mousecircuit Data", Author: "mousecircuit" },
        });
    }

}

export default XlsxExport;