import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import axios from "axios";

const firebaseConfig = {
  apiKey: "AIzaSyBzj2io_X07LO7Gucv6n3ht7-a60r3Z9_Y",
  authDomain: "mousecircuits.firebaseapp.com",
  projectId: "mousecircuits",
  storageBucket: "mousecircuits.appspot.com",
  messagingSenderId: "157457310557",
  appId: "1:157457310557:web:7bbb825ae43148116dfeb4",
};
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
if (process.env.NODE_ENV == "development") {
    db.useEmulator("localhost", process.env.VUE_APP_FIRESTORE_LOCAL_PORT);
}

class FirebaseApi {
  static async getFormMetadata() {
          const snapshot = await firebase
        .firestore()
        .collection("metadata")
        .doc("form")
        .get();

      if (snapshot.exists) {
        return snapshot.data();
      } else {
        throw Error("Form metadata does not exist");
      }
    }
    
  static async getTableMetadata() {
    const snapshot = await firebase
      .firestore()
      .collection("metadata")
      .doc("table")
      .get();

    if (snapshot.exists) {
      return snapshot.data();
    } else {
      throw Error("Table metadata does not exist");
    }
  }

  /**
   *
   * @returns
   * @throws Exception
   */
  static async getBrainHierarchy() {
    const snapshot = await firebase
      .firestore()
      .collection("metadata")
      .doc("brainHierarchy")
      .get();

    if (snapshot.exists) {
      return snapshot.data();
    } else {
      throw Error("Brain regions do not exist");
    }
  }

  /**
   * This method is used to submit study for further approval
   * @param {*} study
   * @returns
   */
  static async submitStudy(pi, study) {
    const response = await axios.post(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS}/submitStudy`,
      { pi, study }
    );

    return response.data.data;
  }

  static async approveStudy(updatedStudy) {
    const response = await axios.post(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS}/approveStudy`,
      updatedStudy
    );

    return response.data.data;
  }

  static async getStudy(id) {
    const document = await db.collection("approvedStudies").doc(id).get();

    if (!document.exists) {
      throw new Error(`No pending study of id ${id} found`);
    }

    return document.data();
  }

  static async getPendingStudy(id) {
    const document = await db.collection("pendingStudies").doc(id).get();

    if (!document.exists) {
      throw new Error(`No pending study of id ${id} found`);
    }

    return document.data();
  }

  /**
   *
   * @param {*} email
   * @returns
   */
  static async getPiInfoFromEmail(email) {
    const snapshot = await db
      .collection("principalInvestigators")
      .where("email", "==", email)
      .get();

    if (snapshot.empty) {
      return null;
    }
    return snapshot.docs[0].data();
  }

  static async getPiInfo(id) {
    const document = await db
      .collection("principalInvestigators")
      .doc(id)
      .get();

    if (!document.exists) {
      throw new Error(`PI with id ${id} not found`);
    }

    return document.data();
  }

  static async getStudies() {
    const documents = await db.collection("approvedStudies").get();

    if (documents.empty) {
      throw new Error("No studies found");
    }
    const studies = [];

    documents.docs.forEach((document) => {
      studies.push(document.data());
    });

    return studies;
  }
}
export default FirebaseApi;
