export default {
  methods: {
    /**
     * This method is meant to use to validate text inform in forms. It tests if
     * the given input is valid
     * @param {*} text
     * @return {Object} an object that contains that contains two fields: status, msg
     * E.g. {status: false, msg: This field cannot be empty}
     *
     * status: status is a boolean indicator that is set to true if the input is valid (false otherwise)
     * msg: If the status is false, msg contains explanation. Otherwise, it will be null
     */
    validateTextInput(text, allowEmptyString = false) {
      if (text == null) return { status: false, msg: "This field is required" };

      // If empty string is not allowed but the user provided an empty string, return false
      if (!allowEmptyString && text.trim().length == 0)
        return { status: false, msg: "This field cannot be empty" };

      return { status: true, msg: null };
    },
  },
};
