<template>
  <b-form-group :label="label" class="text-left">
    <b-input-group>
      <!-- For fields that require two or more selection -->
      <b-form-select
        v-if="initialDataType === 'Array'"
        v-model="userInput"
        :disabled="!editing"
        :options="selectOptions"
        :multiple="true"
        :select-size="4"
      ></b-form-select>
      <b-form-input
        v-else
        v-model="userInput"
        label="tst"
        :placeholder="placeholder"
        class="text-left"
        :disabled="!editing"
      ></b-form-input>
      <b-input-group-append>
        <!-- Group of buttons to submit or cancel edit -->
        <div v-if="editing">
          <b-button @click="onEdit(null)" variant="outline-danger"
            ><b-icon icon="x"></b-icon
          ></b-button>
          <b-button @click="onEdit(userInput)" variant="outline-success"
            ><b-icon icon="check"></b-icon
          ></b-button>
        </div>

        <!-- Button to allow edit. This should only show if editing and alwaysEdit are false -->
        <div v-if="!(editing || alwaysEdit)">
          <b-button @click="editing = true" variant="outline-secondary"
            ><b-icon icon="pencil-square"></b-icon
          ></b-button>
        </div>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>
<script>
export default {
  name: "EditableTextBox",
  props: {
    value: {
      type: [String, Array, Number, null],
    },
    /**
     * If true, the edit button will never appear. There wil always
     * either be cross or check button
     */
    alwaysEdit: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
    },
    /**
     * If provided, it will use this value as placeholder
     */
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editing: false,
      editedText: null,
      /**
       * This will contain the data type of the field before it was edited
       * This is used to validate the  data type of the fields after the
       * user has submitted their input
       * @values: String, Array, Number, null
       */
      initialDataType: {
        type: String,
        default: null,
      },
    };
  },
  computed: {
    userInput: {
      set(value) {
        if (this.initialDataType == "Number") {
          this.editedText = Number(value);
        } else {
          this.editedText = value;
        }
      },
      get() {
        if (this.editedText == null) {
          return this.initialDataType == "Array" ? [] : null;
        } else if (this.initialDataType == "number") {
          return Number(this.editedText);
        }
        return this.initialDataType == "Array"
          ? this.editedText
          : this.editedText.toString();
      },
    },

    /**
     * If the initialDataType is an array, it will return
     * an array of options for the multiple selection form
     */
    selectOptions() {
      const options = [];
      if (this.initialDataType == "Array") {
        this.value.forEach((item) => {
          options.push({ text: item, value: item });
        });
      }
      return options;
    },
  },
  methods: {
    async onEdit(value) {
      this.$emit("on-edit", value);
      this.editing = false;
    },

    /**
     * Compute the initial data type of the field based
     * on the value from props
     */
    computeInitialDataType() {
      // Check if this is an array
      if (Array.isArray(this.value)) {
        this.initialDataType = "Array";
      } else {
        this.initialDataType = typeof this.value;
      }
    },
  },

  mounted() {
    this.userInput = this.value;
    this.editing = this.alwaysEdit;
  },
  created() {
    this.computeInitialDataType();
  },
  components: {},
};
</script>
<style scoped>
.icon-btn img {
  transition-duration: 0.5s;
  max-height: 20px;
  max-width: 20px;
  width: auto;
}
</style>
