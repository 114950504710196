<template>
  <loading v-if="loading"> </loading>
  <div class="container mt-5" v-else>
    <!-- Personal Information -->

    <b-table-simple class="text-left">
      <b-tr v-for="formField in formFields" :key="formField.code">
        <b-td>{{ formField.name }}</b-td>
        <b-td>
          <a
            v-if="formField.code == 'referenceLink'"
            :href="study[formField.code]"
            target="_blank"
            >{{ study[formField.code] }}</a
          >
          <span v-else>{{ study[formField.code] }}</span>
        </b-td>
      </b-tr>
    </b-table-simple>

    <div class="mt-5 pt-3">
      <action-buttons></action-buttons>
    </div>
    <div class="mt-5 pt-3">
      <custom-footer></custom-footer>
    </div>
  </div>
</template>

<script>
import Loading from "../../common/Loading.vue";
import FirebaseApi from "../../../api/FirebaseApi";
import FormOptions from "../../../data/FormOptions";
import CustomFooter from "../../common/CustomFooter.vue";
import ActionButtons from "../../common/ActionButtons.vue";
/**
 * This page should only be accessible to admin users
 * to edit individual studies in the pending list
 */
export default {
  name: "Study",
  data: function () {
    return {
      loading: true,
      study: {},
      principalInvestigator: {},
    };
  },
  computed: {
    formFields() {
      return FormOptions.formFields;
    },
  },
  methods: {
    async init() {
      try {
        // eslint-disable-next-line no-unused-vars
        const studyId = this.$route.params.id;

        const study = await FirebaseApi.getStudy(studyId);
        // Get the principal investigator for this study
        const pi = await FirebaseApi.getPiInfo(study.piId);

        this.study = Object.assign({}, study);
        this.principalInvestigator = Object.assign({}, pi);

        this.loading = false;
      } catch (err) {
        console.error("Unable to load pending study");
        throw err;
        //   TODO: Show error on UI
      }
    },
  },
  mounted() {
    this.init();
  },
  components: { Loading, CustomFooter, ActionButtons },
};
</script>
<style lang="scss" scoped></style>
