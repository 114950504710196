<template>
  <div v-if="loading"><loading></loading></div>

  <div v-else class="mt-5 pt-3 mb-4">
    <!-- Buttons to toggle path and region -->
    <div>
      <button
        :class="[
          'btn',
          'btn-outline-dark',
          'mr-3',
          currentActiveTab == 'region' ? 'mc-tab-active' : '',
        ]"
        @click="currentActiveTab = 'region'"
      >
        <b-icon class="mc-circle-fill" icon="circle-fill" scale="0.7"></b-icon>
        Region-Based
      </button>

      <button
        :class="[
          'btn',
          'btn-outline-dark',
          'ml-3',
          currentActiveTab == 'path' ? 'mc-tab-active' : '',
        ]"
        @click="currentActiveTab = 'path'"
      >
        <b-icon class="mc-circle-fill" icon="circle-fill" scale="0.7"></b-icon>
        Pathway-Based
      </button>
    </div>

    <!-- Charts -->
    <div class="mt-5 mb-5" :key="this.currentActiveTab">
      <div v-for="chart in charts" :key="chart" class="mt-2">
        <!-- Wrap around the flexbox to justify contents in the center -->
        <div class="d-flex justify-content-center mb-5">
          <div style="width: 40%">
            <chart-renderer
              :chartConfiguration="buildChartConfig(chart)"
            ></chart-renderer>
          </div>
        </div>
        <!--  -->
      </div>

      <!-- Number of Behaviors -->
      <div class="d-flex justify-content-center mb-5 mt-2">
        <div style="width: 40%">
          <p style="font-size: 35px; font-weight: bold; color: #ff00ba">
            Number of Behavior
          </p>
          <!-- Extra padding to add to height and center centent -->
          <div class="d-flex justify-content-around pt-5 pb-5 jumbotron">
            <div>
              <p>Total for low range</p>

              <!-- Extra padding to increase the width of the div -->
              <span class="badge badge-light pl-3 pr-3">
                <div>
                  <h1>
                    {{ sumRangeNumAnimals.low }}
                  </h1>
                </div>
              </span>
            </div>
            <div>
              <p>Total for high range</p>
              <div>
                <h1>{{ sumRangeNumAnimals.high }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Empty div to add whitespace -->
    <div style="height: 40px"></div>
    <div class="mb-5 mt-5">
      <action-buttons></action-buttons>
    </div>
    <div>
      <custom-footer></custom-footer>
    </div>
  </div>
</template>
<script>
import ChartRenderer from "./components/ChartRenderer.vue";
import Loading from "../../common/Loading.vue";
import FirebaseApi from "../../../api/FirebaseApi";
import ActionButtons from "../../common/ActionButtons.vue";
import CustomFooter from "../../common/CustomFooter.vue";
import _ from "lodash";
export default {
  name: "Charts",
  data() {
    return {
      loading: true,
      // Represents the active tab. Possible values: 'region', 'path'
      currentActiveTab: "region",
      charts: ["target_region", "rodent_sex", "methodology", "behavioralTests"],
      studies: [],
    };
  },
  computed: {
    /**
     * Filters our studies based on the active tab
     */
    filteredStudies() {
      return _.filter(
        this.studies,
        (study) => study.studyType === this.currentActiveTab
      );
    },

    /**
     * Returns the pie chart configuration for target region
     */
    rodentSexChartConfig() {
      const labels = [];
      const data = [];
      const colors = [];

      // A dictionary to keep track of the count of different sex
      const dict = {};

      for (const study of this.filteredStudies) {
        dict[study.sex] = dict[study.sex] ? dict[study.sex] + 1 : 1;
      }

      // Now iterate through all the sexes and build the label, data and color array
      const sexes = Object.keys(dict);
      for (let i = 0; i < sexes.length; i++) {
        const sex = sexes[i];
        labels.push(sex);
        data.push(dict[sex]);
        colors.push(this.getColorFromPool(i));
      }

      return {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: colors,
              hoverOffset: 4,
            },
          ],
        },
        options: {
          plugins: {
            // https://www.chartjs.org/docs/latest/configuration/title.html
            title: {
              display: true,
              text: "Rodent Sex",
              position: "top",
              align: "center",
              color: "#ff00ba",
              font: {
                size: 35,
                weight: "bold",
              },
            },

            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 1,
              },
            },
          },
        },
      };
    },

    /**
     * Returns the pie chart configuration for target region
     */
    methodologyChartConfig() {
      const labels = [];
      const data = [];
      const colors = [];

      // A dictionary to keep track of the count of different sex
      const dict = {};
      let total = 0;
      for (const study of this.filteredStudies) {
        // iterate through methodology

        for (const item of study.methodology || []) {
          dict[item] = dict[item] ? dict[item] + 1 : 1;
          total++;
        }
      }

      // Now iterate through all the sexes and build the label, data and color array
      const methodologies = Object.keys(dict);
      for (let i = 0; i < methodologies.length; i++) {
        const methodology = methodologies[i];
        labels.push(methodology);
        data.push(dict[methodology]);
        colors.push(this.getColorFromPool(i * 3));
      }

      return {
        type: "doughnut",
        data: {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: colors,
              hoverOffset: 4,
            },
          ],
        },
        options: {
          plugins: {
            maintainAspectRatio: false,
            // https://www.chartjs.org/docs/latest/configuration/title.html
            title: {
              display: true,
              text: "Methodology",
              position: "top",
              align: "center",
              color: "#ff00ba",
              font: {
                size: 35,
                weight: "bold",
              },
            },
            legend: {
              display: true,
              position: "right",
            },
            tooltip: {
              callbacks: {
                // eslint-disable-next-line no-unused-vars
                label: (tooltipItem, data) => {
                  return `Count ${tooltipItem.raw}. (${(
                    (tooltipItem.raw / total) *
                    100
                  ).toFixed(2)}%)`;
                },
              },
            },
          },
        },
      };
    },

    /**
     * Returns the pie chart configuration for behavioral tests
     */
    behavioralTestsChartConfig() {
      const labels = [];
      const data = [];
      const colors = [];

      // A dictionary to keep track of all the behavioral tests
      const dict = {};
      // let total = 0;
      for (const study of this.filteredStudies) {
        // iterate through methodology

        for (const item of study.behavioralTests || []) {
          dict[item] = dict[item] ? dict[item] + 1 : 1;
          // total++;
        }
      }

      // Now iterate through all the sexes and build the label, data and color array
      const behavioralTests = Object.keys(dict);
      for (let i = 0; i < behavioralTests.length; i++) {
        const methodology = behavioralTests[i];
        labels.push(methodology);
        data.push(dict[methodology]);
        colors.push(this.getColorFromPool(i * 3));
      }

      // eslint-disable-next-line no-debugger
      debugger;

      return {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: colors,
              hoverOffset: 4,
            },
          ],
        },
        options: {
          plugins: {
            maintainAspectRatio: false,
            // https://www.chartjs.org/docs/latest/configuration/title.html
            title: {
              display: true,
              text: "Behavioral Tests",
              position: "top",
              align: "center",
              color: "#ff00ba",
              font: {
                size: 35,
                weight: "bold",
              },
            },
            legend: {
              display: false,
              // position: "right",
            },
          },
        },
      };
    },

    /**
     * Returns the pie chart configuration for target region
     */
    targetRegionChartConfig() {
      const labels = [];
      const data = [];
      const colors = [];

      // A dictionary to keep track of the number of studies for each region
      const dict = {};

      for (const study of this.filteredStudies) {
        dict[study.targetFrom] = dict[study.targetFrom]
          ? dict[study.targetFrom] + 1
          : 1;

        // For pathway study also account for the targetTo
        if (this.currentActiveTab == "path") {
          dict[study.targetTo] = dict[study.targetTo]
            ? dict[study.targetTo] + 1
            : 1;
        }
      }

      // Now iterate through all the studies and build the label, data and color array
      const regions = Object.keys(dict);
      for (let i = 0; i < regions.length; i++) {
        const region = regions[i];
        labels.push(region);
        data.push(dict[region]);
        colors.push(this.getColorFromPool(i));
      }

      return {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: colors,
              hoverOffset: 4,
            },
          ],
        },
        options: {
          plugins: {
            maintainAspectRatio: false,
            // https://www.chartjs.org/docs/latest/configuration/title.html
            title: {
              display: true,
              text: "Target Region",
              position: "top",
              align: "center",
              color: "#ff00ba",
              font: {
                size: 35,
                weight: "bold",
              },
            },
            legend: {
              display: false,
              // position: "right",
            },
          },
        },
      };
    },
    /**
     * Returns the sum each of lower and higher ranges of the number of animals used
     *
     */
    sumRangeNumAnimals() {
      const sumRange = { low: 0, high: 0 };

      for (const study of this.filteredStudies) {
        sumRange.low += study.numAnimals[0];
        sumRange.high += study.numAnimals[1];
      }
      return sumRange;
    },
  },
  methods: {
    /**
     * This method builds and returns chart configuration based on the provided chart name
     */
    buildChartConfig(name) {
      switch (name) {
        case "target_region":
          return this.targetRegionChartConfig;
        case "rodent_sex":
          return this.rodentSexChartConfig;
        case "methodology":
          return this.methodologyChartConfig;
        case "behavioralTests":
          return this.behavioralTestsChartConfig;
      }
    },

    /**
     * This function has a static list of colors. Given the index, it returns
     * the color at that index. If the index is out of bounds, it returns the color
     * from the beginning of the list
     */
    getColorFromPool(index) {
      const pool = [
        "rgba(76,136,39,1.0)",
        "rgba(127, 5, 95, 1.0)",
        "rgba(175,94,202,0.8)",
        "rgba(252,109,127,1.0)",
        "rgba(173,138,26,1.0)",
        "rgba(6,232,29,1.0)",
        "rgba(175,40,48,1.0)",
        "rgba(57,199,33,1.0)",
        "rgba(249,142,55,1.0)",
        "rgba(217,29,137,1.0)",
        "rgba(111,122,208,0.8)",
        "rgba(134,7,58,0.8)",
        "rgba(95,160,152,1.0)",
        "rgba(114,223,191,1.0)",
        "rgba(8,93,90,1.0)",
        "rgba(108,52,214,0.8)",
        "rgba(86,171,30,0.8)",
        "rgba(232,96,61,1.0)",
        "rgba(156,195,211,0.8)",
        "rgba(148,50,45,0.8)",
        "rgba(172,237,1,0.8)",
        "rgba(3,8,21,1.0)",
        "rgba(70,72,152,1.0)",
        "rgba(219,85,147,1.0)",
        "rgba(36,142,36,0.8)",
        "rgba(166,141,243,0.8)",
        "rgba(29,250,74,1.0)",
        "rgba(221,207,14,0.8)",
        "rgba(50,247,115,0.8)",
        "rgba(77,105,47,0.8)",
        "rgba(209,113,114,1.0)",
        "rgba(239,249,9,0.8)",
        "rgba(79,177,140,0.8)",
        "rgba(14,101,68,0.8)",
        "rgba(195,9,185,1.0)",
        "rgba(70,175,90,0.8)",
        "rgba(246,198,167,0.8)",
        "rgba(147,167,175,0.8)",
        "rgba(156,97,178,0.8)",
        "rgba(139,2,194,0.8)",
        "rgba(245,183,25,0.8)",
        "rgba(1,236,31,0.8)",
        "rgba(141,187,45,0.8)",
        "rgba(60,172,82,0.8)",
        "rgba(12,190,24,0.8)",
        "rgba(166,243,204,0.8)",
        "rgba(113,178,27,0.8)",
        "rgba(136,147,124,0.8)",
        "rgba(223,157,225,0.8)",
        "rgba(198,124,122,0.8)",
      ];

      return pool[index % pool.length];
    },

    async init() {
      try {
        // eslint-disable-next-line no-unused-vars
        const studies = await FirebaseApi.getStudies();
        this.studies = studies;

        this.loading = false;
      } catch (err) {
        console.error(err);
      }
    },
  },
  mounted() {
    this.init();
  },
  components: {
    ChartRenderer,
    Loading,
    ActionButtons,
    CustomFooter,
  },

  ChartRenderer,
};
</script>
<style scoped>
.mc-tab-active .mc-circle-fill {
  visibility: visible !important;
}
.mc-circle-fill {
  color: #ff008a;
  visibility: hidden;
}

.btn-outline-dark:hover {
  background-color: #f7f7f7 !important;
  color: #343a40 !important;
}
</style>