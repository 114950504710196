import Vue from "vue";
import Router from "vue-router";


import DataForm from "./components/pages/form/DataForm";
import TableTabs from "./components/pages/datatable/TableTabs";
import ReviewStudy from "./components/pages/study/ReviewStudy";
import Study from "./components/pages/study/Study";
import Home from "./components/pages/Home.vue";
import Connectome from "./components/pages/Connectome.vue";
import Goal from "./components/pages/goal/Goal.vue"
import Publications from "./components/pages/publications/Publications.vue";
import Charts from "./components/pages/charts/Charts.vue";
import Team from "./components/pages/team/Team.vue";
import TermsOfUse from "./components/pages/terms/TermsOfUse.vue";
import Method from "./components/pages/method/Method.vue";
Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [

    {
      path: "/",
      // alias: "/mc2",
      name: "Home",
      component: Home
    },
    {
      path: "/goal",
      name: "Goal",
      component: Goal,
    },
    {
      path: "/publications",
      name: "Publications",
      component: Publications,
    },
    {
      path: "/charts",
      name: "Charts",
      component: Charts,
    },
    {
      path: "/team",
      name: "Team",
      component: Team,
    },
    {
      path: "/connectome",
      name: "Connectome",
      component: Connectome
    },
    {
      path: "/upload",
      name: "DataForm",
      component: DataForm,
    },
    {
      path: "/review/:id",
      alias: "/approve/:id",
      name: "ReviewStudy",
      component: ReviewStudy,
      props: true
    },
    {
      path: "/study/:id",
      name: "Study",
      component: Study,
      props: true
    },
    {
      path: "/view",
      name: "DataTable",
      component: TableTabs,
    },
    {
      path: "/terms",
      name: "TermsOfUse",
      component: TermsOfUse,
    },
    {
      path: "/method",
      name: "Method",
      component: Method,
    }
  ],
});
