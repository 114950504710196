import FirebaseApi from "../../api/FirebaseApi";

export default {
  namespaced: true,
  state: () => ({
    //   Contain's personal information of the person who conducted this experiment
    pi: {
      fullName: null,
      email: null,
    },

    study: {
      labName: null,
      author: {
        fullName: null,
        email: null,
      },
      // Default value is "path". Possible values are "path", "region"
      studyType: "path",
      // The code of the brain region that is studied. In case of pathways data, it is the
      // code of the first brain region (out of the two)
      targetFrom: null,
      // This will remain null if the studyType is "region". However, if the studyType is
      // "path", then this will contain the code of the 2nd brain region that is studied (out of thw two)
      targetTo: null,
      animalModel: null,
      sex: null,
      age: null,
      numAnimals: [0, 0],
      strains: null,
      domain: [],
      behavioralTests: null,
      methodology: null,
      promoters: null,
      virus: null,
      virusAmount: null,
      targetRegionCoordinate: null,
      stimulPatternOrCnoDose: null,
      directionSummary: null,
      reference: null,
      referenceLink: null,
      otherBehaviorRun: null,
      postHocConfirmInjection: null,
      observeBeforePertub: null,
      impactOnFear: null,
      impactOnAnxiety: null,
      impactOnDepression: null,
      impactOnSocialReinforcement: null,
      impactOnSocialMemory: null,
      impactOnSociability: null,
      impactOnDominance: null,
      impactOnAggression: null,
    },

  }),
  mutations: {
    fullName(state, fullName) {
      state.study.author.fullName = fullName;
    },
    email(state, email) {
      state.study.author.email = email;
    },
    labName(state, labName) {
      state.study.labName = labName;
    },
    piFullName(state, fullName) {
      state.pi.fullName = fullName;
    },
    piEmail(state, email) {
      state.pi.email = email;
    },
    studyType(state, type) {
      state.study.studyType = type;
    },
    targetFrom(state, targetFrom) {
      state.study.targetFrom = targetFrom;
    },
    targetTo(state, targetTo) {
      state.study.targetTo = targetTo;
    },
    animalModel(state, animalModel) {
      state.study.animalModel = animalModel;
    },
    numAnimals(state, num) {
      state.study.numAnimals = num;
    },
    age(state, age) {
      state.study.age = age;
    },
    sex(state, sex) {
      state.study.sex = sex;
    },
    strains(state, strains) {
      state.study.strains = strains;
    },
    domain(state, domain) {
      state.study.domain = domain || [];
    },
    behavioralTests(state, behavioralTests) {
      state.study.behavioralTests = behavioralTests;
    },
    methodology(state, methodology) {
      state.study.methodology = methodology;
    },
    promoters(state, promoters) {
      state.study.promoters = promoters;
    },
    virus(state, virus) {
      state.study.virus = virus;
    },
    virusAmount(state, amount) {
      state.study.virusAmount = amount;
    },
    targetRegionCoordinate(state, coordinate) {
      state.study.targetRegionCoordinate = coordinate;
    },
    stimulPatternOrCnoDose(state, stimulPatternOrCnoDose) {
      state.study.stimulPatternOrCnoDose = stimulPatternOrCnoDose;
    },
    impactOnFear(state, impact) {
      state.study.impactOnFear = impact;
    },
    impactOnAnxiety(state, impact) {
      state.study.impactOnAnxiety = impact;
    },
    impactOnDepression(state, impact) {
      state.study.impactOnDepression = impact;
    },
    impactOnSocialReinforcement(state, impact) {
      state.study.impactOnSocialReinforcement = impact;
    },
    impactOnSocialMemory(state, impact) {
      state.study.impactOnSocialMemory = impact;
    },
    impactOnSociability(state, impact) {
      state.study.impactOnSociability = impact;
    },
    impactOnDominance(state, impact) {
      state.study.impactOnDominance = impact;
    },
    impactOnAggression(state, impact) {
      state.study.impactOnAggression = impact;
    },
    directionSummary(state, directionSummary) {
      state.study.directionSummary = directionSummary;
    },
    reference(state, reference) {
      state.study.reference = reference;
    },
    referenceLink(state, reference) {
      state.study.referenceLink = reference;
    },
    otherBehaviorRun(state, otherBehaviorRun) {
      state.study.otherBehaviorRun = otherBehaviorRun;
    },
    postHocConfirmInjection(state, postHocConfirmInjection) {
      state.study.postHocConfirmInjection = postHocConfirmInjection;
    },
    observeBeforePertub(state, observeBeforePertub) {
      state.study.observeBeforePertub = observeBeforePertub;
    },
  },
  getters: {
    fullName(state) {
      return state.study.author.fullName;
    },

    email(state) {
      return state.study.author.email;
    },
    labName(state) {
      return state.study.labName;
    },
    piFullName(state) {
      return state.pi.fullName;
    },
    piEmail(state) {
      return state.pi.email;
    },
  },
  actions: {
    async submitStudy({ state }) {
      await FirebaseApi.submitStudy(state.pi, state.study);
    }
  },
};
