<template>
  <div>
    <b-row>
      <b-col cols="3">
        <img
          :src="getImage(biography.image)"
          class="img-fluid rounded-circle"
          style="width: 265px; height: auto"
          alt="Team member"
        />
      </b-col>

      <!-- Title and description -->
      <b-col cols="9" class="text-left">
        <p
          class="mb-2"
          style="color: #6b007c; font-weight: bold; font-size: 1.1rem"
        >
          {{ biography.name }}
        </p>
        <p>
          <em>{{ biography.title }}</em>
        </p>
        <p style="font-size: 0.95rem; color: #6f6f6f">
          {{ biography.description }}
        </p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "Biography",
  props: {
    /**
     * Biography data. It is an object with the following structure:
     * name: string
     * title: string
     * description: string
     * photo: string
     */
    biography: {},
  },
  data() {
    return {};
  },
  methods: {
    getImage(img) {
      return require(`@/assets/${img}`);
    },
  },
};
</script>
<style scoped>
</style>