<template>
  <div>
    <b-button :size="size" variant="light" @click="$emit('click')">
      <b-icon icon="chevron-left"></b-icon>
      Go Back</b-button
    >
  </div>
</template>
<script>
/**
 * This component represents a "back button".
 */
export default {
  name: "BackButton",
  props: {
    /**
     * @values "sm", "md", "lg"
     */
    size: {
      type: String,
      default: "md",
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
