var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('div',{staticStyle:{"max-height":"100px"}},[_c('loading')],1):_c('div',{staticClass:"p-4"},[_c('b-row',[_c('b-col',{staticClass:"text-left p-0",attrs:{"col":"","md":"7","sm":"12"}},[_c('div',{ref:"edge-bundling-chart",attrs:{"id":"edge-bundling"}},[_c('svg')]),_c('div',[_c('div',{staticClass:"d-sm-block d-md-none d-lg-none p-0 mt-4"}),_c('b-card',{staticClass:"text-left",attrs:{"no-header":"","no-body":""}},[_c('b-card-body',{staticClass:"p-0"},[_c('div',{staticClass:"legend text-left text-muted"},[_c('b-row',{staticClass:"m-0 pl-1"},[_c('b-col',{staticClass:"legend-item pl-0"},[_c('b-icon',{staticClass:"text-red",attrs:{"icon":"square-fill"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Increase")])],1),_c('b-col',{staticClass:"legend-item"},[_c('b-icon',{staticClass:"text-blue",attrs:{"icon":"square-fill"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Decrease")])],1),_c('b-col',{staticClass:"legend-item"},[_c('b-icon',{staticClass:"text-black",attrs:{"icon":"square-fill"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Increase & Decrease")])],1)],1)],1)]),_c('b-card-footer',{attrs:{"footer-class":"text-left p-0 pl-1 m-0"}},[_c('small',{staticClass:"text-muted"},[_vm._v(" Click on the node or the edges to interact with the connectome ")])])],1)],1)]),_c('b-col',{attrs:{"col":"","md":"5","sm":"12"}},[_c('div',{staticClass:"d-flex flex-column text-left"},[_c('div',[_c('searchable-select',{attrs:{"options":_vm.domainOption,"label":"Domain","placeholder":"Select Domain","selectMultiple":true,"displayTextAndValue":false},on:{"on-input":function($event){return _vm.updateFilters('domain', $event)}}})],1),_c('searchable-select',{attrs:{"options":[
            { text: 'mice', value: 'mice' },
            { text: 'rat', value: 'rat' },
          ],"label":"Animal Model","placeholder":"Select Animal Model","selectMultiple":true,"displayTextAndValue":false},on:{"on-input":function($event){return _vm.updateFilters('animalModel', $event)}}}),_c('searchable-select',{attrs:{"options":[
            { text: 'increase', value: 'increase' },
            { text: 'decrease', value: 'decrease' },
            { text: 'no change', value: 'no change' },
          ],"label":"Direction Summary","placeholder":"Selection Direction Summary","selectMultiple":true,"displayTextAndValue":false},on:{"on-input":function($event){return _vm.updateFilters('directionSummary', $event)}}}),_c('searchable-select',{attrs:{"options":[
            { text: 'Opto Stimulation', value: 'opto stimulation' },
            { text: 'Opto Inhibition', value: 'opto inhibition' },
            { text: 'Chem Stimulation', value: 'chem stimulation' },
            { text: 'Chem Inhibition', value: 'chem inhibition' },
          ],"label":"Methodology","placeholder":"Select Methodology","selectMultiple":true,"displayTextAndValue":false},on:{"on-input":function($event){return _vm.updateFilters('methodology', $event)}}}),_c('searchable-select',{attrs:{"options":_vm.behavioralTestsOptions,"label":"Behavioral Tests","placeholder":"Select Behavioral Tests","selectMultiple":true,"displayTextAndValue":false},on:{"on-input":function($event){return _vm.updateFilters('behavioralTests', $event)}}}),_c('searchable-select',{attrs:{"options":_vm.brainRegionsOptions,"label":"Brain Region","placeholder":"Select Brain Region","selectMultiple":true,"displayTextAndValue":false},on:{"on-input":function($event){return _vm.updateFilters('brainRegions', $event)}}})],1)])],1),(_vm.floatingInfoBox != null)?_c('div',[_c('floating-info-box',{attrs:{"coords":_vm.floatingInfoBox.coords,"studies":_vm.floatingInfoBox.studies || [],"source":_vm.floatingInfoBox.source,"target":_vm.floatingInfoBox.target},on:{"on-close":function($event){_vm.floatingInfoBox = null}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }