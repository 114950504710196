<template>
  <div class="mc-container container-fluid">
    <div class="mc-text-container">
      <div class="text-left mt-5 pd-4">
        <h1 class="mc-title">Terms of use</h1>
      </div>

      <div class="mc-content text-left mt-4 w-75">
        <!-- Citations -->
        <p style="font-size: 1.1rem"><strong>Citations</strong></p>
        <p>
          When using any data or material found on or downloaded from
          MouseCircuits.org in your publication(s), please include each the
          complete website name (MouseCircuits.org) and the original paper:
        </p>
        <p>
          Anderson, K. R., & Dumitriu, D. (2020). MouseCircuits.org: An online
          repository to guide the circuit era of disordered affect.<span
            style="color: #ff00ba"
          >
            BioRxiv link.</span
          >
          (https://doi.org/10.1101/2020.02.16.951608)
        </p>
        <!-- Disclaimer -->
        <p style="font-size: 1.1rem" class="mt-4">
          <strong>Disclaimer</strong>
        </p>
        <p>
          Browsing and using the data in MouseCircuits.org is at the user's own
          risk. Columbia University, their leaders, and employees make no
          representations, warranties or guarantees of any kind or nature
          regarding the content or accuracy of the information.
        </p>
        <p>
          Links are provided as a convenience only, and MouseCircuits.org makes
          no representations, warranties or guarantees regarding their contents.
        </p>
      </div>
    </div>

    <div class="mt-5">
      <action-buttons></action-buttons>
    </div>
    <div class="mt-5 pt-3">
      <custom-footer></custom-footer>
    </div>
  </div>
</template>
<script>
import ActionButtons from "../../common/ActionButtons.vue";
import CustomFooter from "../../common/CustomFooter.vue";
export default {
  name: "TermsOfUse",
  computed: {},
  components: {
    ActionButtons,
    CustomFooter,
  },
};
</script>
<style scoped>
.mc-text-container {
  margin-left: 5%;
  margin-right: 25%;
}
.mc-title {
  color: #ff00ba;
  font-weight: 700;
  font-size: 45px;
}
</style>