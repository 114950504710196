<template>
  <b-form @submit="onSubmit" class="text-left">
    <!-- Coordinate of the target region -->
    <b-form-group
      id="mc-targetRegionCoordinate-label"
      label="Coordinates of target region(s) (AP, ML, DV)"
      label-for="mc-targetRegionCoordinate"
      description="Input the stereotaxic coordinates of the virus injection in the format of AP, ML, and DV."
    >
      <b-form-input
        id="mc-targetRegionCoordinate"
        v-model="targetRegionCoordinate"
        type="text"
        placeholder="E.g. -3.0, 5.4, -8.0; -3.0, 5.4, -6.6"
        required
      ></b-form-input>
    </b-form-group>

    <!-- Stimulus pattern or CNO dose -->
    <b-form-group
      id="mc-stimulPatternOrCnoDose-label"
      label="Stimulation pattern or CNO dose"
      label-for="mc-stimulPatternOrCnoDose"
      description="Input all information needed to replicate the finding regarding either 
      the stimulus pattern for optogenetic manipulation or the timing and CNO dose for 
      chemogenetic manipulation."
    >
      <b-form-input
        id="mc-stimulPatternOrCnoDose"
        v-model="stimulPatternOrCnoDose"
        type="text"
        placeholder="E.g. CaMKII-ChR2 in LA @ 20-Hz, 10 ms pulse stimulation"
      ></b-form-input>
    </b-form-group>

    <!-- Impact on domain of study -->
    <b-form-group
      v-if="$store.state.form.study.domain.includes('fear')"
      id="mc-impactOnFear-label"
      label="Impact on Fear"
      label-for="mc-impactOnFear"
      description="If fear-like behavior was tested, give a brief summary of the results. "
    >
      <b-form-input
        id="mc-impactOnFear"
        v-model="impactOnFear"
        type="text"
        placeholder="E.g. No change Open field test"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      v-if="$store.state.form.study.domain.includes('anxiety')"
      id="mc-impactOnAnxiety-label"
      label="Impact on Anxiety"
      label-for="mc-impactOnAnxiety"
      description="If anxiety-like behavior was tested, give a brief summary of the results"
    >
      <b-form-input
        id="mc-impactOnAnxiety"
        v-model="impactOnAnxiety"
        type="text"
        placeholder="E.g. No change Open field test"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      v-if="$store.state.form.study.domain.includes('depression')"
      id="mc-impactOnDepression-label"
      label="Impact on Depression"
      label-for="mc-impactOnDepression"
      description="If depressive-like behavior was tested,  give a brief summary of the results. "
    >
      <b-form-input
        id="mc-impactOnDepression"
        v-model="impactOnDepression"
        type="text"
        placeholder="E.g. No change Open field test"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      v-if="$store.state.form.study.domain.includes('socialReinforcement')"
      id="mc-impactOnSocialReinforcement-label"
      label="Impact on Social Reinforcement"
      label-for="mc-impactOnSocialReinforcement"
      description="If social reinforcement-like behavior was tested, give a brief summary of the results. "
    >
      <b-form-input
        id="mc-impactOnSocialReinforcement"
        v-model="impactOnSocialReinforcement"
        type="text"
        placeholder="E.g. No change Open field test"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      v-if="$store.state.form.study.domain.includes('socialMemory')"
      id="mc-impactOnSocialMemory-label"
      label="Impact on Memory"
      label-for="mc-impactOnSocialMemory"
      description="If memory-like behavior was tested, give a brief summary of the results. "
    >
      <b-form-input
        id="mc-impactOnSocialMemory"
        v-model="impactOnSocialMemory"
        type="text"
        placeholder="E.g. No change Open field test"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      v-if="$store.state.form.study.domain.includes('sociability')"
      id="mc-impactOnSocialMemory"
      label="Impact on Sociability"
      label-for="mc-impactOnSocialMemory"
      description="If sociability-like behavior was tested, give a brief summary of the results."
    >
      <b-form-input
        id="mc-impactOnSociability"
        v-model="impactOnSociability"
        type="text"
        placeholder="E.g. No change Open field test"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      v-if="$store.state.form.study.domain.includes('dominance')"
      id="mc-impactOnDominance-label"
      label="Impact on Dominance"
      label-for="mc-impactOnDominance"
      description="If dominance-like behavior was tested, give a brief summary of the results. "
    >
      <b-form-input
        id="mc-impactOnDominance"
        v-model="impactOnDominance"
        type="text"
        placeholder="E.g. No change Open field test"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      v-if="$store.state.form.study.domain.includes('aggression')"
      id="mc-impactOnAggression-label"
      label="Impact on Aggression"
      label-for="mc-impactOnAggression"
      description="If aggression-like behavior was tested, give a brief summary of the results. "
    >
      <b-form-input
        id="mc-impactOnAggression"
        v-model="impactOnAggression"
        type="text"
        placeholder="E.g. No change Open field test"
      ></b-form-input>
    </b-form-group>

    <!-- Direction summary -->
    <searchable-select
      id="mc-directionSummary-label"
      label="Direction Summary"
      :options="directionSummaryOptions"
      @on-input="directionSummary = $event"
      :selectMultiple="true"
      :displayTextAndValue="false"
      description="What is the bottom line summary of your study? What does your 
      study say about the role of the region or pathway in behavior direction in regards to baseline levels?"
    >
      <!-- This contains error messages -->
      <template v-slot:invalid-feedback>
        <div
          v-if="
            validation.directionSummary != null &&
            !validation.directionSummary.status
          "
          style="font-size: 80%; color: red"
        >
          {{ validation.directionSummary.msg }}
        </div>
      </template>
    </searchable-select>

    <!-- Reference -->
    <b-form-group
      id="mc-reference-label"
      label="Reference"
      label-for="mc-reference"
    >
      <b-form-input
        id="mc-reference"
        v-model="reference"
        type="text"
        placeholder="E.g. Covington et al., 2010"
        required
      ></b-form-input>
    </b-form-group>

    <!-- Link to reference -->
    <b-form-group
      id="mc-reference-link-label"
      label="Provide a link to the reference"
      label-for="mc-reference-link"
    >
      <b-form-input
        id="mc-reference-link"
        v-model="referenceLink"
        type="text"
        placeholder="E.g. https://yourreference.com/reference"
        required
      ></b-form-input>
    </b-form-group>

    <!-- Other behaviors run -->
    <b-form-group
      id="mc-otherBehaviorRun-label"
      label="Other Behaviors run?"
      label-for="mc-otherBehaviorRun"
      description="List any other behavioral tests run that were not under the anxiety, fear, and depression domain."
    >
      <b-form-input
        id="mc-otherBehaviorRun"
        v-model="otherBehaviorRun"
        type="text"
        placeholder="E.g. Place preference, nose poke"
      ></b-form-input>
    </b-form-group>

    <!-- Other behaviors run -->
    <b-form-group
      id="mc-postHocConfirmInjection-label"
      label="Post-hoc confirmation of injections"
      label-for="mc-postHocConfirmInjection"
      description="Was there some form of a post-hoc confirmation of the viral 
      injection location? 
      May input yes and a short description such as ‘yes, immunohistochemistry’."
    >
      <b-form-input
        id="mc-postHocConfirmInjection"
        v-model="postHocConfirmInjection"
        type="text"
        placeholder="E.g. Yes, noted was confirmed"
        required
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="mc-observeBeforePertub-label"
      label="Observe before pertub"
      label-for="mc-observeBeforePertub"
      description="Was some type of observation of the targeted circuit or region in an 
      endogenous state done prior to perturbation? A loose definition of observation includes: 
      checking that an immediate early gene product is expressed in the region or 
      pathway of interest after behavior, using tracing to ensure a functional connection 
      exists between two regions, utilizing a “trapping” technology to indelibly mark 
      neurons activated by a particular behavior, and in vivo imaging."
      class="mb-0"
    >
      <b-form-select
        id="mc-observeBeforePertub"
        v-model="observeBeforePertub"
        :options="observeBeforePertubOptions"
        required
      ></b-form-select>
    </b-form-group>
    <div class="d-flex justify-content-between mt-5">
      <back-button @click="$emit('on-back')"></back-button>
      <b-button v-if="isDevEnv" variant="primary" @click="random()"
        >Random</b-button
      >
      <b-button
        size="lg"
        :disabled="disableSubmitBtn"
        type="submit"
        class="mc-standard-btn"
        ><b-spinner v-if="disableSubmitBtn" class="mr-2" small></b-spinner
        >Submit</b-button
      >
    </div>
  </b-form>
</template>
<script>
import BackButton from "../../../common/BackButton.vue";
import RandomInt from "../../../../mixins/RandomInt";
import Toast from "../../../../mixins/Toast";
import SearchableSelect from "../../../common/SearchableSelect.vue";
import "vue-slider-component/theme/default.css";
import DevEnv from "../../../../mixins/DevEnv";
export default {
  name: "ImpactAndDirectionForm",
  mixins: [RandomInt, Toast, DevEnv],
  data: function () {
    return {
      /**
       * This object contains the validation status of each fields above. Each attribute
       * corresponds to the validation status of that field in the form above. It will
       * initially contain null but once the user begins filling the form, the attributes
       * will contain actual values (most probably through the setters in computed properties)
       * When valid, each attribute will look like this
       * {status: true, msg: null}
       * When invalid, each attribute will look like this
       * {status: false, msg: "Error message for this field"}
       */
      validation: {
        targetRegionCoordinate: null,
        stimulPatternOrCnoDose: null,
        impactOnFear: null,
        impactOnAnxiety: null,
        impactOnDepression: null,
        directionSummary: null,
        reference: null,
        otherBehaviorRun: null,
        postHocConfirmInjection: null,
        observeBeforePertub: null,
      },

      // This will be set to true, when the validation fails
      // This way, we will know the user tried to
      // submit the form but failed
      failedSubmit: false,

      disableSubmitBtn: false,
    };
  },
  computed: {
    // Figure out a way to reuse this with DomainAndMethodForm.vue
    domainOptions() {
      return [
        { text: "Aggression", value: "aggression" },
        { text: "Anxiety", value: "anxiety" },
        { text: "Depression", value: "depression" },
        { text: "Dominance", value: "dominance" },
        { text: "Fear", value: "fear" },
        { text: "Sociability", value: "sociability" },
        { text: "Social Memory", value: "socialMemory" },
        { text: "Social Reinforcement", value: "socialReinforcement" },
      ];
    },

    directionSummaryOptions() {
      return [
        { text: "Increase", value: "increase" },
        { text: "Decrease", value: "decrease" },
        { text: "No Change", value: "no change" },
      ];
    },

    observeBeforePertubOptions() {
      return [
        { text: "Select yes or no", value: null },
        { text: "Yes", value: "yes" },
        { text: "No", value: "no" },
      ];
    },

    targetRegionCoordinate: {
      get: function () {
        return this.$store.state.form.study.targetRegionCoordinate;
      },
      set: function (newVal) {
        this.$store.commit("form/targetRegionCoordinate", newVal);
      },
    },
    stimulPatternOrCnoDose: {
      get: function () {
        return this.$store.state.form.study.stimulPatternOrCnoDose;
      },
      set: function (newVal) {
        this.$store.commit("form/stimulPatternOrCnoDose", newVal);
      },
    },
    impactOnFear: {
      get: function () {
        return this.$store.state.form.study.impactOnFear;
      },
      set: function (newVal) {
        this.$store.commit("form/impactOnFear", newVal);
      },
    },
    impactOnAnxiety: {
      get: function () {
        return this.$store.state.form.study.impactOnAnxiety;
      },
      set: function (newVal) {
        this.$store.commit("form/impactOnAnxiety", newVal);
      },
    },
    impactOnDepression: {
      get: function () {
        return this.$store.state.form.study.impactOnDepression;
      },
      set: function (newVal) {
        this.$store.commit("form/impactOnDepression", newVal);
      },
    },
    directionSummary: {
      get: function () {
        return this.$store.state.form.study.directionSummary;
      },
      set: function (newVal) {
        this.$store.commit("form/directionSummary", newVal);
      },
    },
    reference: {
      get: function () {
        return this.$store.state.form.study.reference;
      },
      set: function (newVal) {
        this.$store.commit("form/reference", newVal);
      },
    },
    referenceLink: {
      get: function () {
        return this.$store.state.form.study.referenceLink;
      },
      set: function (newVal) {
        this.$store.commit("form/referenceLink", newVal);
      },
    },
    otherBehaviorRun: {
      get: function () {
        return this.$store.state.form.study.otherBehaviorRun;
      },
      set: function (newVal) {
        this.$store.commit("form/otherBehaviorRun", newVal);
      },
    },
    postHocConfirmInjection: {
      get: function () {
        return this.$store.state.form.study.postHocConfirmInjection;
      },
      set: function (newVal) {
        this.$store.commit("form/postHocConfirmInjection", newVal);
      },
    },
    observeBeforePertub: {
      get: function () {
        return this.$store.state.form.study.observeBeforePertub;
      },
      set: function (newVal) {
        this.$store.commit("form/observeBeforePertub", newVal);
      },
    },

    impactOnSocialReinforcement: {
      get: function () {
        return this.$store.state.form.study.impactOnSocialReinforcement;
      },
      set: function (newVal) {
        this.$store.commit("form/impactOnSocialReinforcement", newVal);
      },
    },

    impactOnSocialMemory: {
      get: function () {
        return this.$store.state.form.study.impactOnSocialMemory;
      },
      set: function (newVal) {
        this.$store.commit("form/impactOnSocialMemory", newVal);
      },
    },
    impactOnSociability: {
      get: function () {
        return this.$store.state.form.study.impactOnSociability;
      },
      set: function (newVal) {
        this.$store.commit("form/impactOnSociability", newVal);
      },
    },

    impactOnDominance: {
      get: function () {
        return this.$store.state.form.study.impactOnDominance;
      },
      set: function (newVal) {
        this.$store.commit("form/impactOnDominance", newVal);
      },
    },

    impactOnAggression: {
      get: function () {
        return this.$store.state.form.study.impactOnAggression;
      },
      set: function (newVal) {
        this.$store.commit("form/impactOnAggression", newVal);
      },
    },
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      // Disable submit button to prevent double submission
      this.disableSubmitBtn = true;

      // Make sure all the forms are valid before submission
      const isValid = this.isFormValid();

      if (isValid) {
        this.failedSubmit = false;
        try {
          await this.$store.dispatch("form/submitStudy");
          this.$emit("on-complete");
        } catch (err) {
          console.error(err);
          this.errorToast("Unable to submit form", err.message);
        }
      } else {
        this.failedSubmit = true;
      }

      this.disableSubmitBtn = false;
    },

    /**
     * This method validates all the forms and returns true if all
     * of them are valid. Otherwise, it returns false;
     */
    isFormValid() {
      let valid = true;
      this.validateForm();
      Object.keys(this.validation).forEach((key) => {
        const validation = this.validation[key];

        if (validation == null || !validation.status) {
          valid = false;
          console.log(validation);
        }
      });

      return valid;
    },

    /**
     * This method validates whether the appropriate fields were selected
     */
    validateForm() {
      // Target Region coordinate is a mandatory field. So no need for further validaton
      this.validation.targetRegionCoordinate = { status: true, msg: null };

      /// Simulation pattern or CNO dose is a mandatory field. So no need for further validaton
      this.validation.stimulPatternOrCnoDose = { status: true, msg: null };

      // Impact on domain (fear, anxiety and depression) is  a non-null field. This doesn't
      // need a separate validation. The setter method automatically populates one of the
      // three fields (impactOnFear, impactOnAnxiety and impactonDepression) based
      // on the domain of study
      this.validation.impactOnAnxiety = { status: true, msg: null };
      this.validation.impactOnFear = { status: true, msg: null };
      this.validation.impactOnDepression = { status: true, msg: null };

      // Direction summary: There should be at least one field
      if (this.directionSummary == null || this.directionSummary.length == 0) {
        this.validation.directionSummary = {
          status: false,
          msg: "This field is required",
        };
      } else {
        this.validation.directionSummary = { status: true, msg: null };
      }

      // Reference is a mandatory field. No need further validation
      this.validation.reference = { status: true, msg: false };

      // Other behavior run is a mandatory field. This needs no further validation
      this.validation.otherBehaviorRun = { status: true, msg: false };

      // Post hoc confirmation injection is a mandatory field. This needs no further validation
      this.validation.postHocConfirmInjection = { status: true, msg: false };

      // Observe before pertub is a mandatory field. This needs no further validation
      this.validation.observeBeforePertub = { status: true, msg: false };
    },

    random() {
      this.targetRegionCoordinate = "-3.0, 5.4, -8.0; -3.0, 5.4, -6.6";

      const coordinates = [
        "1.75, 0.75, 2.65; 1.75, 1.75, 1.7",
        "-1.5, ±1.0, -1.5; -2.5, ±2.0, -1.5; -1.94, ±1.25, -1.0",
      ];
      this.targetRegionCoordinate =
        coordinates[this.randomInt(0, coordinates.length)];

      const patterns = [
        " CaMKIIα-eNpHR3.1 @ continuous light",
        " CaMKIIα-eNpHR3.1 @ continuous light",
      ];
      this.stimulPatternOrCnoDose =
        patterns[this.randomInt(0, patterns.length)];

      const impactOnDomainList = [
        "No difference in Open field test",
        "Photoactivation of BLA-> CeA = anxiolytic and Photoinhibition of BLA-> Cea = anxiogenic",
        "In non-stressed controls, stimulation had no effect ",
      ];

      this.impactOnDomain =
        impactOnDomainList[this.randomInt(0, impactOnDomainList.length)];

      const summary = [
        ["increase"],
        ["decrease"],
        ["increase", "decrease"],
        ["no-change"],
      ];
      this.directionSummary =
        summary[this.randomInt(0, this.directionSummaryOptions.length)].value;

      const references = [
        "Warden et al., 2012",
        "Tye et al., 2013",
        "Chaudhury et al., 2012",
      ];
      this.reference = references[this.randomInt(0, references.length)];
      this.referenceLink = "https://www.jneurosci.org/content/41/43/8858";

      const otherBehaviorRuns = ["Locomotion - no effect", "NOR"];
      this.otherBehaviorRun =
        otherBehaviorRuns[this.randomInt(0, otherBehaviorRuns.length)];

      const postHcConfirmationInjections = [
        "Yes, noted was confirmed",
        "Histological verification",
      ];
      this.postHocConfirmInjection =
        postHcConfirmationInjections[
          this.randomInt(0, postHcConfirmationInjections.length)
        ];

      this.observeBeforePertub =
        this.observeBeforePertubOptions[
          this.randomInt(0, this.observeBeforePertubOptions.length)
        ].value;
    },
  },
  components: { BackButton, SearchableSelect },
};
</script>
<style lang="scss" scoped></style>
