<template>
  <div class="mc-container">
    <div class="d-flex justify-content-around mt-5 pt-3">
      <div class="text-left" style="max-width: 50%">
        <h1 class="mc-title">The goal</h1>
        <div class="mt-5 p-2">
          <p>
            The era of circuit mapping, ushered in by the advent of optogenetics
            and chemogenetics, has changed the landscape of neuroscience
            research and has re-energized the hope for novel understandings of
            disordered affective states. The era of circuit mapping has amassed
            a large amount of data but this data largely exists as stand-alone
            studies. Due to the complexity of whole brain functional
            connectivity, a unified picture is needed. MouseCircuits.org aims to
            serve as a tool for researchers by serving as a centralized and
            consolidated location for circuit-mapping data. MouseCircuits.org
            was established as a “one-stop” entry point to facilitate access to
            relevant data and to encourage data sharing within the neuroscience
            community to maximize data visibility and impact. We believe that
            viewing the rich and precise data from circuit-mapping in such an
            integrative format can aid in informing new studies and in the
            shared vision of many researchers in which circuit dissection
            studies ultimately leads to prevention and treatment of human
            disorders.
          </p>
        </div>
      </div>
      <div>
        <img
          src="@/assets/goal-img.png"
          style="max-height: 467px; width: auto"
          alt="Goal"
          class="img-fluid"
        />
      </div>
    </div>
    <div class="mb-5">
      <action-buttons></action-buttons>
    </div>
    <div>
      <custom-footer></custom-footer>
    </div>
  </div>
</template>
<script>
import ActionButtons from "../../common/ActionButtons.vue";
import CustomFooter from "../../common/CustomFooter.vue";
export default {
  name: "Goal",
  data() {
    return {};
  },
  methods: {},
  components: {
    ActionButtons,
    CustomFooter,
  },
};
</script>
<style scoped>
.mc-container {
  margin-left: 5%;
  margin-right: 5%;
}

.mc-title {
  color: #ff00ba;
  font-weight: 700;
  font-size: 45px;
}
</style>