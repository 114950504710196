export default {
  methods: {
    /**
     * Returns a random integer between min and max value (max exclusive)
     * @param {*} min
     * @param {*} max
     * @returns
     */
    randomInt(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
  },
};
