<template>
  <div>
    <div class="mc-container">
      <div class="text-left mt-5 pt-3" style="max-width: 50%">
        <h1 class="mc-title">Publications</h1>
      </div>

      <div style="margin-top: 75px">
        <b-row
          class="mt-5"
          v-for="publication of publications"
          :key="publication.title"
        >
          <b-col cols="2">
            <img
              :src="getImage(publication.image)"
              :alt="publication.title"
              style="height: auto; width: 175px"
            />
          </b-col>
          <b-col cols="8" class="text-left" align-self="center">
            <span>
              MouseCircuits.org: An online repository to guide the circuit era
              of disordered affect
            </span></b-col
          >
        </b-row>
      </div>
      <div style="margin-top: 100px">
        <action-buttons></action-buttons>
      </div>
      <div style="margin-top: 100px">
        <custom-footer></custom-footer>
      </div>
    </div>
  </div>
</template>
<script>
import ActionButtons from "../../common/ActionButtons.vue";
import CustomFooter from "../../common/CustomFooter.vue";
export default {
  name: "Publications",
  data() {
    return {
      publications: [
        {
          title: "Journal of Neuroscience",
          description:
            "MouseCircuits.org: An online repository to guide the circuit era of disordered affect",
          image: "journal_of_neuroscience.png",
        },
        {
          title: "The preprint server for biology",
          description: "Highlights from the Era of Open Source Web-Based Tools",
          image: "reprient_server_for_biology.png",
        },
      ],
    };
  },
  methods: {
    getImage(img) {
      return require(`@/assets/publications/${img}`);
    },
  },
  components: {
    ActionButtons,
    CustomFooter,
  },
};
</script>
<style scoped>
.mc-container {
  margin-left: 5%;
  margin-right: 5%;
}

.mc-title {
  color: #ff00ba;
  font-weight: 700;
  font-size: 45px;
}
</style>