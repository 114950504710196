<template>
  <b-form @submit="onSubmit" class="text-left">
    <searchable-select
      id="mc-domain"
      label="Domain"
      :options="domainOptions"
      placeholder="Domain"
      description="What domain or category of behavior was tested? Choose any combination of options."
      @on-input="domain = $event"
      :selectMultiple="true"
      :displayTextAndValue="false"
      :state="validation.domain == null ? null : validation.domain.status"
      :initialValue="domain"
    >
      <!-- This contains error messages -->
      <template v-slot:invalid-feedback>
        <div
          v-if="validation.promoters != null && !validation.promoters.status"
          style="font-size: 80%; color: red"
        >
          {{ validation.promoters.msg }}
        </div>
      </template>
    </searchable-select>

    <!-- Behavioral Tests -->
    <searchable-select
      id="mc-behavior"
      label="Behavioral Tests"
      :options="behavioralTestOptions"
      placeholder="Search or select the behavioral tests you performed"
      @on-input="behavioralTests = $event"
      :initialValue="behavioralTests"
      :selectMultiple="true"
      :state="
        validation.behavioralTests == null
          ? null
          : validation.behavioralTests.status
      "
      description="Choose the behavioral test utilized in the study in conjugation with 
      the circuit manipulation? Choose any combination of options."
    >
      <!-- This contains error messages -->
      <template v-slot:invalid-feedback>
        <div
          v-if="
            validation.behavioralTests != null &&
            !validation.behavioralTests.status
          "
          style="font-size: 80%; color: red"
        >
          {{ validation.behavioralTests.msg }}
        </div>
      </template>
    </searchable-select>

    <!-- Methodology -->

    <searchable-select
      id="mc-methodology"
      label="Methodology"
      :options="methodologyOptions"
      placeholder="Methodology"
      description="What circuit mapping technique was utilized?"
      @on-input="methodology = $event"
      :selectMultiple="true"
      :state="
        validation.methodology == null ? null : validation.methodology.status
      "
      :initialValue="methodology"
      :displayTextAndValue="false"
    >
      <!-- This contains error messages -->
      <template v-slot:invalid-feedback>
        <div
          v-if="
            validation.methodology != null && !validation.methodology.status
          "
          style="font-size: 80%; color: red"
        >
          {{ validation.methodology.msg }}
        </div>
      </template>
    </searchable-select>

    <!-- promoters -->
    <searchable-select
      id="mc-promoters"
      label="promoters"
      :options="promoterOptions"
      placeholder="Promoters"
      description="What promoter was used to drive viral expression? 
      Choose any combination of options or add your own."
      @on-input="promoters = $event"
      :selectMultiple="true"
      :state="validation.promoters == null ? null : validation.promoters.status"
      :initialValue="promoters"
    >
      <!-- This contains error messages -->
      <template v-slot:invalid-feedback>
        <div
          v-if="validation.promoters != null && !validation.promoters.status"
          style="font-size: 80%; color: red"
        >
          {{ validation.promoters.msg }}
        </div>
      </template>
    </searchable-select>

    <!-- Virus -->
    <b-form-group
      id="mc-virus-label"
      label="Virus"
      label-for="mc-virus"
      description="What is the exact virus used? 
      Include any applicable information such as the serotype and where it was purchased from. "
    >
      <b-form-input
        id="mc-virus"
        v-model="virus"
        type="text"
        placeholder="E.g. HSV-ChR2-mCherry "
      ></b-form-input>
    </b-form-group>

    <!-- Amount of virus injected -->
    <b-form-group
      id="mc-virusAmount-label"
      label="Amount of virus injected in microliter"
      label-for="mc-virusAmount"
      description="What much of the virus was injected per injection?"
    >
      <b-input-group append="µL" class="mb-2 mr-sm-2 mb-sm-0">
        <b-form-input
          id="mc-virusAmount"
          v-model="virusAmount"
          type="text"
          placeholder="E.g. 0.5 "
        ></b-form-input>
      </b-input-group>
    </b-form-group>

    <div class="d-flex justify-content-between mt-5">
      <back-button @click="$emit('on-back')"></back-button>
      <b-button v-if="isDevEnv" variant="primary" @click="random()"
        >Random</b-button
      >
      <b-button type="submit" size="lg" class="mc-standard-btn">Next</b-button>
    </div>
  </b-form>
</template>
<script>
/**
 * This form gives user the option to choose whether their study studies
 * a brain region or a path between two brain regions. It then, asks them
 * to select the proper path and a region
 */
import _ from "lodash";
import { mapGetters } from "vuex";
import SearchableSelect from "../../../common/SearchableSelect.vue";
import RangeInputValidationMixin from "../../../../mixins/RangeInputValidationMixin";
import BackButton from "../../../common/BackButton.vue";
import RandomInt from "../../../../mixins/RandomInt";
import DevEnv from "../../../../mixins/DevEnv";
import "vue-slider-component/theme/default.css";
export default {
  name: "DomainAndMethod",
  mixins: [RandomInt, RangeInputValidationMixin, DevEnv],
  data: function () {
    return {
      /**
       * This object contains the validation status of each fields above. Each attribute
       * corresponds to the validation status of that field in the form above. It will
       * initially contain null but once the user begins filling the form, the attributes
       * will contain actual values (most probably through the setters in computed properties)
       * When valid, each attribute will look like this
       * {status: true, msg: null}
       * When invalid, each attribute will look like this
       * {status: false, msg: "Error message for this field"}
       */
      validation: {
        domain: null,
        behavioralTests: null,
        methodology: null,
        promoters: null,
      },

      // This will be set to true, when the validation fails
      // This way, we will know the user tried to
      // submit the form but failed
      failedSubmit: false,
    };
  },
  computed: {
    ...mapGetters(["availablePromoters", "availableBehavioralTests"]),

    domainOptions() {
      return [
        { text: "Aggression", value: "aggression" },
        { text: "Anxiety", value: "anxiety" },
        { text: "Depression", value: "depression" },
        { text: "Dominance", value: "dominance" },
        { text: "Fear", value: "fear" },
        { text: "Social Memory", value: "socialMemory" },
        { text: "Sociability", value: "sociability" },
        { text: "Social Reinforcement", value: "socialReinforcement" },
      ];
    },

    behavioralTestOptions() {
      return _.chain(this.availableBehavioralTests)
        .map((test) => {
          return { text: test, value: test };
        })
        .sortBy((option) => {
          return option.text;
        })
        .value();
    },
    methodologyOptions() {
      return [
        { text: "opto stimulation", value: "opto stimulation" },
        { text: "opto inhibition", value: "opto inhibition" },
        { text: "chem stimulation", value: "chem stimulation" },
        { text: "chem inhibition", value: "chem inhibition" },
      ];
    },

    promoterOptions() {
      // First, sort the tests in ascending order
      const options = _.chain(this.availablePromoters)
        .map((promoter) => {
          return { text: promoter, value: promoter };
        })
        .sortBy((option) => {
          return option.text;
        })
        .value();

      // Add other options
      options.push({ value: "other", text: "Other" });

      return options;
    },

    domain: {
      get: function () {
        return this.$store.state.form.study.domain;
      },
      set: function (newVal) {
        this.$store.commit("form/domain", newVal);
      },
    },
    behavioralTests: {
      get: function () {
        return this.$store.state.form.study.behavioralTests;
      },
      set: function (newVal) {
        this.$store.commit("form/behavioralTests", newVal);
      },
    },
    methodology: {
      get: function () {
        return this.$store.state.form.study.methodology;
      },
      set: function (newVal) {
        this.$store.commit("form/methodology", newVal);
      },
    },
    promoters: {
      get: function () {
        return this.$store.state.form.study.promoters;
      },
      set: function (newVal) {
        let val = newVal;
        if (typeof newVal === "string") {
          val = [newVal];
        }

        this.$store.commit("form/promoters", val);
      },
    },
    virus: {
      get: function () {
        return this.$store.state.form.study.virus;
      },
      set: function (newVal) {
        this.$store.commit("form/virus", newVal);
      },
    },
    virusAmount: {
      get: function () {
        return this.$store.state.form.study.virusAmount;
      },
      set: function (newVal) {
        this.$store.commit("form/virusAmount", newVal);
      },
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      // Make sure all the forms are valid before submission

      if (this.isFormValid()) {
        this.failedSubmit = false;
        this.$emit("on-complete");
      } else {
        this.failedSubmit = true;
      }
    },

    /**
     * This method validates all the forms and returns true if all
     * of them are valid. Otherwise, it returns false;
     */
    isFormValid() {
      let valid = true;
      this.validateForm();
      Object.keys(this.validation).forEach((key) => {
        const validation = this.validation[key];

        if (validation == null || !validation.status) {
          valid = false;
        }
      });

      return valid;
    },

    /**
     * This method validates whether the appropriate fields were selected
     */
    validateForm() {
      // We want to make sure that the user has selected at least one options
      if (this.domain == null || this.domain.length == 0) {
        this.validation.domain = {
          status: false,
          msg: "Please select a domain",
        };
      } else {
        this.validation.domain = {
          status: true,
          msg: null,
        };
      }

      // For this, we want to make sure that the user has selected at least one option
      if (this.behavioralTests == null || this.behavioralTests.length == 0) {
        this.validation.behavioralTests = {
          status: false,
          msg: "This field is required",
        };
      } else {
        this.validation.behavioralTests = { status: true, msg: null };
      }

      // This also has built in validation
      if (this.methodology == null || this.methodology.length == 0) {
        this.validation.methodology = {
          status: false,
          msg: "This field is required",
        };
      } else {
        this.validation.methodology = { status: true, msg: null };
      }

      if (this.strains == null || this.strains.length == 0) {
        this.validation.strains = {
          status: true,
          msg: `This field is required`,
        };
      }

      // For this, we want to make sure that the user has selected at least one option
      if (this.promoters == null || this.promoters.length == 0) {
        this.validation.promoters = {
          status: false,
          msg: "This field is required",
        };
      } else {
        this.validation.promoters = { status: true, msg: null };
      }
    },

    random() {
      this.domain = [
        this.domainOptions[this.randomInt(0, this.domainOptions.length)].value,
      ];

      this.behavioralTests =
        this.behavioralTestOptions[
          this.randomInt(0, this.behavioralTestOptions.length)
        ].value;

      this.methodology =
        this.methodologyOptions[
          this.randomInt(0, this.methodologyOptions.length)
        ].value;

      this.promoters =
        this.promoterOptions[
          this.randomInt(0, this.promoterOptions.length)
        ].value;

      this.virus = "randomVirus";
      this.virusAmount = "0.5";
    },
  },
  components: { SearchableSelect, BackButton },
};
</script>

<style></style>
