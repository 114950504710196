<template>
  <div class="text-left">
    <b-form @submit="onSubmit" class="text-left">
      <!-- Target From -->

      <!-- Study type. This determines whether the data they are providing
        is related to a study of a brain oregion or a path between two brain regions-->
      <b-form-group
        label="Select Input Type"
        label-for="mc-studyType-select"
        description="Was a path or region studied? Path indicates that the study was conducted on a 
        pathway between two brain regions. Region indicates that the study was conducted on a 
        single brain region"
      >
        <b-form-select
          id="mc-studyType-select"
          v-model="studyType"
          :options="[
            { text: 'Path', value: 'path' },
            { text: 'Region', value: 'region' },
          ]"
          required
        >
        </b-form-select>
      </b-form-group>
      <b-row>
        <!-- If the input type is a path, take half the available space because other half is needed for another field
            But, if this is a region, take the full space. Also, the label should change depending on whether the input type
            is a path or a region -->
        <b-col :cols="studyType == 'path' ? '6' : '12'">
          <searchable-select
            id="mc-target-from"
            :label="studyType == 'region' ? 'Brain Region' : 'From'"
            :options="brainRegionOptions"
            placeholder="Search or Select a brain region"
            @on-input="targetFrom = $event"
            :state="
              validation.targetFrom == null
                ? null
                : validation.targetFrom.status
            "
            :initialValue="targetFrom"
            :description="targetFromDescription"
          >
            <!-- This contains error messages -->
            <template v-slot:invalid-feedback>
              <div
                v-if="
                  validation.targetFrom != null && !validation.targetFrom.status
                "
                style="font-size: 80%; color: red"
              >
                {{ validation.targetFrom.msg }}
              </div>
            </template>
          </searchable-select>
        </b-col>

        <!-- Target To -->
        <b-col v-if="studyType == 'path'" cols="6">
          <searchable-select
            id="mc-target-to"
            label="To"
            :options="brainRegionOptions"
            placeholder="Search or Select a brain region"
            @on-input="targetTo = $event"
            key="target-to"
            :state="
              validation.targetTo == null ? null : validation.targetTo.status
            "
            :initialValue="targetTo"
            description="What is the downstream brain region of the pathway studied? 
            Regions follow the Allen Brain Atlas nomenclature. 
            (Note: if there was more than one brain region studied, upload each as a separate entry)."
          >
            <!-- This contains error messages -->
            <template v-slot:invalid-feedback>
              <div
                v-if="
                  validation.targetTo != null && !validation.targetTo.status
                "
                style="font-size: 80%; color: red"
              >
                {{ validation.targetTo.msg }}
              </div>
            </template>
          </searchable-select>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-between mt-5">
        <back-button @click="$emit('on-back')"></back-button>
        <b-button v-if="isDevEnv" variant="primary" @click="random()"
          >Random</b-button
        >
        <b-button
          size="lg"
          class="mc-standard-btn"
          type="submit"
          variant="primary"
          >Next</b-button
        >
      </div>
    </b-form>
  </div>
</template>
<script>
/**
 * This form gives user the option to choose whether their study studies
 * a brain region or a path between two brain regions. It then, asks them
 * to select the proper path and a region
 */
import _ from "lodash";
import { mapGetters } from "vuex";
import { REGION_NAME, REGION_CODE, REGIONS } from "../../../../util/Constants";
import SearchableSelect from "../../../common/SearchableSelect.vue";
import BackButton from "../../../common/BackButton.vue";
import RandomInt from "../../../../mixins/RandomInt";
import DevEnv from "../../../../mixins/DevEnv";
export default {
  name: "PathOrRegionForm",
  mixins: [RandomInt, DevEnv],
  data: function () {
    return {
      /**
       * This object contains the validation status of each fields above. Each attribute
       * corresponds to the validation status of that field in the form above. It will
       * initially contain null but once the user begins filling the form, the attributes
       * will contain actual values (most probably through the setters in computed properties)
       * When valid, each attribute will look like this
       * {status: true, msg: null}
       * When invalid, each attribute will look like this
       * {status: false, msg: "Error message for this field"}
       */
      validation: {
        studyType: null,
        targetFrom: null,
        targetTo: null,
      },

      // This will be set to true, when the validation fails
      // This way, we will know the user tried to
      // submit the form but failed
      failedSubmit: false,
    };
  },
  computed: {
    ...mapGetters(["brainHierarchy"]),
    /**
     * Returns All available brain regions from the database that
     * user can select. It will only return the leaf nodes of the hierarchical
     * structure of brain regions (as stored in the database). The user can only
     * select amont the leaf nodes
     */
    brainRegionOptions() {
      const hierarchy = this.brainHierarchy;

      const stack = [];

      stack.push(hierarchy);
      // Get all the leaf nodes of the structure via a dfs traversal
      // and
      // 1. Create an object with attribute text and value.
      //    text will contain the name of the region. value will contain
      //    the code of the region
      //    E.g {name: "Primary somatosensory area, nose", code: "SS-n"} would
      //    be added to options as {text: "Primary somatosensory area, nose", value: "SS-n"}
      const options = [];
      while (stack.length != 0) {
        const node = stack.pop();

        // If the node has any children, add them to the stack
        // Otherwise, we will know that this node is a leaf node
        if (node[REGIONS] != null && node[REGIONS].length > 0) {
          for (let i = 0; i < node.regions.length; i += 1) {
            const childRegion = node[REGIONS][i];

            stack.push(childRegion);
          }
        } else {
          options.push({
            text: node[REGION_NAME],
            value: node[REGION_CODE],
          });
        }
      }
      // Sort and return
      return _.sortBy(options, (option) => {
        return option.text.toLowerCase();
      });
    },

    /**
     * Returns the study type for the target from form.
     * It should show different descriptions for the study type "region" and "path"
     */
    targetFromDescription() {
      if (this.studyType == "region") {
        return `What brain region was studied? Regions follow the Allen Brain Atlas nomenclature. 
        (Note: if there was more than one brain region studied, upload each as a separate entry).`;
      } else {
        return `What is the upstream brain region of the pathway studied? 
        Regions follow the Allen Brain Atlas nomenclature. 
        (Note: if there was more than one brain region studied, upload each as a separate entry).`;
      }
    },

    studyType: {
      get: function () {
        return this.$store.state.form.study.studyType;
      },
      set: function (newVal) {
        this.$store.commit("form/studyType", newVal);

        // If the studyType is path, also mark the targetTo as null
        if (newVal === "region") {
          this.targetTo = null;
        }
      },
    },
    targetFrom: {
      get: function () {
        return this.$store.state.form.study.targetFrom;
      },
      set: function (newVal) {
        this.$store.commit("form/targetFrom", newVal);
      },
    },
    targetTo: {
      get: function () {
        return this.$store.state.form.study.targetTo;
      },
      set: function (newVal) {
        this.$store.commit("form/targetTo", newVal);
      },
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      // Make sure all the forms are valid before submission
      this.validateForm();
      const isvalid =
        this.validation.studyType.status &&
        this.validation.targetFrom.status &&
        this.validation.targetTo.status;

      if (isvalid) {
        this.failedSubmit = false;
        this.$emit("on-complete");
      } else {
        this.failedSubmit = true;
      }
    },

    /**
     * This method validates whether the appropriate fields were selected
     */
    validateForm() {
      // Make sure that studyType is provided
      if (this.studyType == null) {
        this.validation.studyType = {
          status: false,
          msg: "This field is required",
        };
      } else {
        this.validation.studyType = { status: true, msg: null };
      }
      // Make sure that targetFrom is provided
      if (this.targetFrom == null) {
        this.validation.targetFrom = {
          status: false,
          msg: "This field is required",
        };
      } else {
        this.validation.targetFrom = { status: true, msg: null };
      }

      if (this.studyType === "path" && this.targetTo == null) {
        this.validation.targetTo = {
          status: false,
          msg: "This field is required if your study contains path data",
        };
      } else {
        this.validation.targetTo = { status: true, msg: null };
      }
    },

    random() {
      this.studyType = ["path", "region"][
        this.randomInt(0, ["path", "region"].length)
      ];

      this.targetFrom =
        this.brainRegionOptions[
          this.randomInt(0, this.brainRegionOptions.length)
        ].value;

      if (this.studyType == "path") {
        this.targetTo =
          this.brainRegionOptions[
            this.randomInt(0, this.brainRegionOptions.length)
          ].value;
      }
    },
  },
  components: { SearchableSelect, BackButton },
};
</script>

<style></style>
