<template>
  <div class="mb-3">
    <b-form-group :label="label" :description="description" class="mb-1">
      <div
        :class="[
          'mc-keep-dropdown',
          'mc-user-search-container',
          expanded ? 'active' : '',
        ]"
        v-on:click.stop="showDropDown"
      >
        <b-input-group>
          <!-- Input group with a button prepended to the right -->
          <b-form-input
            ref="mc-searchable-input"
            class="shadow-none mc-searchable-dropdown"
            :placeholder="placeholder"
            @blur="closeDropDown(true)"
            v-model="searchString"
            :state="state"
          ></b-form-input>

          <!-- Chevron button to the right -->
          <b-input-group-append style="z-index: -1">
            <b-button class="mc-dropdown-btn">
              <b-icon-chevron-down
                scale="0.8"
                style="cursor: pointer"
              ></b-icon-chevron-down
            ></b-button>
          </b-input-group-append>
        </b-input-group>

        <!-- This list group will show all the options that match the
      search string -->
        <div v-if="expanded" class="mc-list-group w-100" style="z-index: 2">
          <b-list-group>
            <b-list-group-item
              v-for="option in displayOptions"
              @click.stop="onItemSelected(option)"
              class="pt-1 pb-1 mc-list-item"
              :key="option.value"
              >{{ option.text }}
              <span v-if="displayTextAndValue">({{ option.value }})</span>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
      <!-- For injecting error messages in case of invalid -->
      <slot name="invalid-feedback"></slot>
    </b-form-group>

    <!-- If the user selected the "other" option from the previous select options, ask them to specify -->
    <!-- Placeholder for this field should be the value of the second item in options (if options has at least two child) 
      Why second item? Becuase the first item may be a null value with a prompt ("such as "Select Brain Region")-->

    <editable-textbox
      v-if="selectedValue == 'other'"
      label="Please specify"
      :value="null"
      :alwaysEdit="true"
      placeholder="please specify"
      @on-edit="onCustomInput($event)"
    ></editable-textbox>

    <!-- This will draw badges for each value in the userInputs array -->
    <b-badge
      v-for="(value, index) in inputValues"
      variant="light"
      class="p-1 font-weight-normal muted mr-1"
      tag="div"
      style="font-size: 15px; border: 1px solid gray"
      :key="index"
    >
      <!-- Cross sign -->
      <span>
        {{ value }}
      </span>
      <!-- Make the wrapping div relative because the cross inside has absolute position -->

      <div @click="removeInput(index)" class="mc-bage-cross ml-2">&#10005;</div>
    </b-badge>
  </div>
</template>
<script>
import _ from "lodash";
import { BIconChevronDown } from "bootstrap-vue";
import EditableTextbox from "./EditableTextbox.vue";

/**
 * This component creates a searchable select input.
 *
 * @example ../../../docs/readme/SearchableSelect.md
 */
export default {
  name: "SearchableSelect",
  props: {
    /**
     * Form Label
     */
    label: {
      type: String,
    },
    /**
     * List of options for the select box
     * Example: [{text: "Text", value: "value"}, {text: "Test2", value: "2alue2"}]
     */
    options: {
      type: Array,
      required: true,
    },

    /**
     * Sets the `placeholder` attribute value on the form control
     */
    placeholder: {
      type: String,
      required: false,
      default: "Search",
    },

    /**
     * If this is true, it allows the user to select one or more options.
     */
    selectMultiple: {
      type: Boolean,
      default: false,
    },

    /**
     * If, not null, this will show up as a description towards the bottom of the
     * select field. This is meant to give additional information about the
     * field (For example: Please select all that apply)
     */
    description: {
      type: [String, null],
      default: null,
    },

    /**
     * This describes the state of the form. If true, it will mean this form
     * is in valid state. If false, it means it is not in a valid state.
     * null indicates no state.
     * The parent component must validate and provided state for this component
     * @values true, false, null
     */
    state: {
      type: [Boolean, null],
      default: null,
    },

    /**
     * Initially selected values. This is either a single
     * value from the options or an array of values.
     */
    initialValue: {
      type: [Array, String, null],
    },

    /**
     * If true, the dropdown will show both the text
     * and value from options. For example, {text: "Text", value: "value"}
     * would show up as "Text (value)". Otherwise, it will only show text
     */
    displayTextAndValue: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      searchString: "",
      // If true, the select menu will expand to show all the dropdown options
      expanded: false,

      // This contains the latest value that the user has selected.
      selectedValue: null,

      // This contains all the values that the user has provided.
      // If the "selectMultiple" flag is set, it will contains all the options selected and the value provided in the "other" field.
      // If it is not set, it will only contain the latest value (either the one selected from the options or the one entered in the "other field")
      inputValues: [],
    };
  },
  computed: {
    /**
     * This returns a list of options from the given prop that match
     * the search string. Note: search string is copared to the text
     * field of the option object. Not the value because users only see
     * the text, not the value
     */
    displayOptions() {
      if (this.expanded == false) return [];

      const searchString = this.searchString.toLowerCase();

      return _.chain(this.options)
        .filter((option) => {
          const text = option.text || "";
          const value = option.value || "";

          return (
            text.toLowerCase().includes(searchString) ||
            value.toLowerCase().includes(searchString)
          );
        })
        .value();
    },
  },
  watch: {
    /**
     * Whenever, the user selected value changes, this function is called. It will
     * emit an event which contains the selected value as
     */
    inputValues(values) {
      /**
       * If the selectMultiple is passed true in props, send an array. Otherwise, sends a single string value
       *
       * @event on-input
       * @property {Array<string> | string} - If multi select mode is on, it will be an array of string. If only the single select mode is on, it will be a string
       */
      this.$emit("on-input", this.selectMultiple ? values : values[0]);
    },
  },
  methods: {
    /**
     * This is called when the user submits the custom input
     */
    onCustomInput(value) {
      this.onItemSelected({ text: value, value: value });
    },

    /**
     * This method is called when the user selects an option from the drop down.
     * It will set the approprivate data field and hide the dropdown
     *
     * @param {Object} item Selected item. Example: {text: "text", value: "value"}
     */
    onItemSelected(item) {
      const value = item.value;
      this.selectedValue = value;
      this.closeDropDown();

      // If multiple select is not enabled, make sure to clear out the values
      // before adding new ones. This way, there will always be one value that is selected
      if (!this.selectMultiple) {
        this.inputValues = [];
      }

      // Only add the values if it's not "other" and null and if it's not already in the input Values
      const isAdd =
        value !== "other" && value != null && !this.inputValues.includes(value);
      if (isAdd) {
        this.inputValues.push(value);
      }

      // Reset the search string
      this.searchString = "";
    },
    showDropDown() {
      this.$refs["mc-searchable-input"].focus();
      this.expanded = true;
    },

    /**
     * This method closes the dropdown options with an option to delay the close by a random value.
     *
     * Why delay?
     * Well, when user clicks on the dropdown item, the input form will  trigger blur event (because the focus has been shifted from the input form).
     * However, we want the click event (from the dropdown item) to register before the blur event (from the input form). This heuristic delay
     * makes that happen (almost all the time). There may be few cases where this delay is simply not enough but the cost of such failure is so little that
     * we won't care.
     *
     * @param {Boolean} delay. if true, the dropdown will be closed after a delay of 150 milliseconds
     */
    closeDropDown(delay = false) {
      if (delay) {
        setTimeout(() => {
          this.expanded = false;
        }, 150);
      } else {
        this.expanded = false;
      }
    },

    /**
     * This removes the user-input from the userInputs array from the given index.
     */
    removeInput(index) {
      this.inputValues.splice(index, 1);
    },
  },
  mounted() {
    // // If the user has provided initial selected values, set them

    if (this.initialValue) {
      if (Array.isArray(this.initialValue)) {
        this.inputValues = [...this.initialValue];
      } else {
        this.inputValues = [this.initialValue];
      }
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BIconChevronDown,
    EditableTextbox,
  },
};
</script>
<style scoped>
.mc-user-search-container {
  position: relative;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.25rem;
}
/* when active, show blue border around the search container */
.active.mc-user-search-container {
  border-top: 1px solid #96c8da !important;
  border-right: 1px solid #96c8da !important;
  border-left: 1px solid #96c8da !important;
}

.active .mc-list-group {
  border: 1px solid #96c8da !important;
  padding-bottom: 0.5rem;
}

.mc-list-group {
  position: absolute;
  max-height: 300px;
  overflow-y: scroll;
}

.mc-list-item {
  z-index: 2;
  border: none;
}

.mc-list-item:hover {
  background-color: #0c79fa;
  color: white;
  cursor: default;
}

.mc-bage-cross {
  display: inline;
  cursor: pointer;
  color: gray;
  transition: 100ms ease-in;
  overflow: hidden; /* This prevents the div from expanding when the font expands"*/
}

.mc-bage-cross:hover {
  font-size: 1.001rem;
}

.mc-searchable-dropdown {
  border: 0px;
}

.mc-dropdown-btn {
  background-color: #ffffff;
  color: gray;
  border: 0px;
}
</style>
