<template>
  <loading v-if="loading"></loading>
  <div ref="mc-table" class="mb-2" v-else>
    <vue-good-table
      :line-numbers="true"
      :search-options="{
        enabled: true,
        placeholder: 'Search this table',
      }"
      :line-number="true"
      :columns="columns"
      :rows="rows"
      compactMode
      :pagination-options="{ enabled: true }"
      @on-cell-click="onCellClick"
    >
    </vue-good-table>
  </div>
</template>
<script>
// import the styles
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Loading from "../../../common/Loading.vue";
export default {
  name: "TableComponent",
  props: {
    dataType: {
      type: String,
      default: "path",
    },
  },
  data: function () {
    return {
      loading: true,
      globalErrorMsg: null,
    };
  },
  computed: {
    columns() {
      return this.$store.getters["table/columns"];
    },
    rows() {
      return this.$store.getters["table/rows"];
    },
  },
  methods: {
    onCellClick(params) {
      // params.row - row object
      // params.column - column object
      // params.rowIndex - index of this row on the current page.
      // params.event - click event
      let routeData = this.$router.resolve({
        name: "Study",
        params: { id: params.row.id },
      });
      window.open(routeData.href, "_blank");
    },
    async init() {
      try {
        await this.$store.dispatch("table/loadStudies");

        this.loading = false;
      } catch (err) {
        this.globalErrorMsg = err.message;
        throw err;
      }
    },
  },
  mounted() {
    this.init();
  },

  destroyed() {
    window.removeEventListener("resize", () => {
      console.log("Window resize listener removed");
    });
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VueGoodTable,
    Loading,
  },
};
</script>
<style scoped></style>
