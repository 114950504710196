<template>
  <loading v-if="loading"> </loading>
  <div class="container-fluid" v-else>
    <!-- Personal Information -->
    <div style="width: 60%; display: inline-block; vertical-align: top">
      <b-table-simple small caption-top class="text-left">
        <caption>
          Personal Information about the person who submitted this study
        </caption>
        <b-tr>
          <b-td>Full Name</b-td>
          <b-td>{{ study.author.fullName }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Email</b-td>
          <b-td>{{ study.author.email }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Lab Name</b-td>
          <b-td>{{ study.labName }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Full Name of Lab PI</b-td>
          <b-td>{{ principalInvestigator.fullName }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>PI's email</b-td>
          <b-td>{{ principalInvestigator.email }}</b-td>
        </b-tr>
      </b-table-simple>

      <hr />
      <editable-textbox
        v-for="formField in formFields"
        :key="formField.code"
        :value="study[formField.code]"
        :label="formField.name"
        @on-edit="onEdit(formField.code, $event)"
      ></editable-textbox>

      <div class="w-100 text-right mt-2 mb-5">
        <b-button
          :disabled="disableApproveBtn"
          variant="success"
          size="lg"
          @click="approve"
          >Approve</b-button
        >
      </div>
    </div>
    <div style="width: 35%; display: inline-block" class="text-left">
      <pre>{{ study }}</pre>
    </div>
  </div>
</template>

<script>
import Loading from "../../common/Loading.vue";
import FirebaseApi from "../../../api/FirebaseApi";
import EditableTextbox from "../../common/EditableTextbox.vue";
import FormOptions from "../../../data/FormOptions";
/**
 * This page should only be accessible to admin users
 * to edit individual studies in the pending list
 */
export default {
  name: "ReviewStudy",
  data: function () {
    return {
      loading: true,
      study: {},
      principalInvestigator: {},
      disableApproveBtn: false,
    };
  },
  computed: {
    formFields() {
      return FormOptions.formFields;
    },
  },
  methods: {
    async init() {
      try {
        // eslint-disable-next-line no-unused-vars
        const studyId = this.$route.params.id;

        const study = await FirebaseApi.getPendingStudy(studyId);
        // Get the principal investigator of this study
        const user = await FirebaseApi.getPiInfo(study.piId);

        this.study = Object.assign({}, study);
        this.principalInvestigator = Object.assign({}, user);

        this.loading = false;
      } catch (err) {
        console.error("Unable to load pending study");
        throw err;
        //   TODO: Show error on UI
      }
    },
    onEdit(fieldKey, value) {
      this.study[fieldKey] = value;
    },
    async approve() {
      this.disableApproveBtn = true;
      try {
        const approvedStudy = await FirebaseApi.approveStudy(this.study);

        this.$bvToast.toast(
          "This study has been approved. The user who submitted this study has been notified",
          {
            title: "Approved",
            variant: "success",
            solid: true,
          }
        );

        setTimeout(() => {
          this.$router.push({
            name: "Study",
            params: { id: approvedStudy.id },
          });
        }, 2000);
      } catch (err) {
        console.error(err);

        this.$bvToast.toast(`${err.message}`, {
          title: `Unable to approve study`,
          variant: "danger",
          solid: true,
        });
      }
    },
  },
  mounted() {
    this.init();
  },
  components: { Loading, EditableTextbox },
};
</script>
<style lang="scss" scoped></style>
