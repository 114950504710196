export default {
  methods: {
    /**
     * This method validates whether the given input is in the provided
     * range (including the lower and upper limit)
     * @param {number} low
     * @param {number} high
     * @param {Array<number> | number} value The actual value that needs
     * to be validated
     */
    validateRangeInputInclusive(low, high, value) {
      if (typeof value === "number") {
        if (value >= low && value <= high) return { status: true, msg: null };
        // Else return false
        return {
          status: false,
          msg: `The provided number ${value} is not in the required range of ${low} and ${high}`,
        };
      } else if (Array.isArray(value) && value.length == 2) {
        const isRange = value[0] >= low && value[1] <= high;

        if (isRange) return { status: true, msg: null };

        // Else return false
        return {
          status: false,
          msg: `The provided range [${value[0]}, ${value[1]}] is not in the required range of ${low} and ${high}`,
        };
      }

      // If above data types don't match, return invalid
      return {
        status: false,
        msg: `Invalud data type ${typeof value} provided`,
      };
    },
  },
};
