<template>
  <!-- If the current page is a home page, add background
image to the whole page -->
  <div
    id="app"
    :class="currentRouteName == 'Home' ? 'mc-background-image' : ''"
  >
    <div clas="mc-navbar">
      <div>
        <!-- If the current page is not a home page, only add background
        image to the navbar -->
        <b-navbar
          toggleable="lg"
          variant="faded"
          type="dark"
          :class="[
            'mc-navbar-custom',
            currentRouteName == 'Home' ? '' : 'mc-background-image',
          ]"
        >
          <b-navbar-brand :to="{ name: 'Home' }">
            <img
              src="@/assets/logo-white.svg"
              alt="Logo"
              style="height: auto; width: 220px"
            />
            <b-badge
              class="ml-3 mr-0 mt-0 mb-0"
              style="
                background-color: #ff00ba !important;
                cursor: default;
                font-weight: normal;
              "
              ><div sty>Ver 1.1</div></b-badge
            >
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item
                class="mc-nav-item"
                @click="click('goal')"
                :to="{ name: 'Goal' }"
                ><span ref="goal" @mouseover="mouseOver" @mouseout="mouseOut"
                  >Goal</span
                ></b-nav-item
              >
              <b-nav-item
                class="mc-nav-item"
                @click="click('method')"
                :to="{ name: 'Method' }"
                ><span ref="method" @mouseover="mouseOver" @mouseout="mouseOut"
                  >Method</span
                ></b-nav-item
              >
              <b-nav-item
                class="mc-nav-item"
                @click="click('team')"
                :to="{ name: 'Team' }"
                ><span ref="team" @mouseover="mouseOver" @mouseout="mouseOut"
                  >Team</span
                ></b-nav-item
              >
              <b-nav-item
                class="mc-nav-item"
                @click="click('publication')"
                :to="{ name: 'Publications' }"
                ><span
                  ref="publication"
                  @mouseover="mouseOver"
                  @mouseout="mouseOut"
                  >Publications</span
                ></b-nav-item
              >
              <b-nav-item
                class="mc-nav-item"
                @click="click('charts')"
                :to="{ name: 'Charts' }"
                ><span ref="charts" @mouseover="mouseOver" @mouseout="mouseOut"
                  ><strong>Charts</strong></span
                ></b-nav-item
              >
              <b-nav-item
                class="mc-nav-item"
                @click="click('database')"
                :to="{ name: 'DataTable' }"
                ><span
                  ref="database"
                  @mouseover="mouseOver"
                  @mouseout="mouseOut"
                  ><strong>Database</strong></span
                ></b-nav-item
              >
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    mouseOver(e) {
      e.target.classList.add("underline");
    },
    mouseOut(e) {
      e.target.classList.remove("underline");
    },
    /**
     * This method is called when a nav item is clicked..
     * @param {string} ref - The ref of the nav item that is clicked.
     */
    click(ref) {
      const refs = Object.keys(this.$refs);

      for (let _ref of refs) {
        if (ref !== _ref) {
          this.$refs[_ref].classList.remove("active");
        }
      }

      this.$refs[ref].classList.add("active");
    },
  },
  components: {},
};
</script>

<style scoped>
@font-face {
  font-family: "Gilroy", "Helvetica Neue", Helvetica, Arial, sans-serif;
  src: url("assets/fonts/Gilroy-Regular.ttf");
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  min-height: 100vh;
  font-family: "Gilroy";
}

.mc-background-image {
  background-image: url("~@/assets/background.jpg");
  background-size: cover;
}

.mc-nav-item > .nav-link {
  color: rgba(255, 255, 255, 1) !important;
}

.mc-navbar-custom {
  min-height: 120px;
}

.active,
.underline {
  border-bottom: 3px solid #ff00ba;
}
</style>

<!-- Global Styles -->
<style>
.mc-standard-btn {
  background-color: #ff008a;
  border: 1px solid #e6007c;
}
</style>