export default {
    methods: {
        /**
         * This method returns alpha value (for opacity) based on the given integer parameter. It uses
         * the following rules to compute the alpha value:
         * 1. If the parameter is 1 or less, then the alpha value is 0.4 (i.e. minimul acceptable value).
         * 2. If the parameter is 10 or more, then the alpha value is 1.0 (i.e. maximum acceptable value).
         * 3. Otherwise, the alpha value is in the range of 0.4 to 1.0.
         * */
        getLinkAlpha(arrLength) {


            // Define minimum values. Array length of given size or less will have given alpha value.
            const min = { alpha: 0.6, size: 1 };

            // Define maximum values. Array length of given size or more will have given alpha value.
            const max = { alpha: 1.0, size: 10 };

            if (arrLength <= min.size) {
                return min.alpha
            }
            else if (arrLength >= max.size) {
                return max.alpha
            }
            else {
                // The alpha value is minimum acceptable value plus the same percentage of the range of minumum and maximum acceptable values
                // as array length is of maximum value.
                return min.alpha + arrLength / 100 * max.size * (max.alpha - min.alpha);
            }
        }
    }
}