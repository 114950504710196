<template>
  <div>
    <img
      src="@/assets/logo-black.svg"
      alt="Logo"
      style="height: auto; width: 220px"
    />
    <p class="text-muted mb-0" style="margin-top: 26px">
      <small
        >When using any material from MouseCircuits.org, please cite the website
      </small>
    </p>
    <p class="mt-0">
      <small
        ><span
          @click="termsOfUse"
          style="color: #ff00ba; font-decoration: underline; cursor: pointer"
          >Terms of use</span
        >
      </small>
    </p>
  </div>
</template>
<script>
export default {
  name: "CustomFooter",
  methods: {
    termsOfUse() {
      this.$router.push("/terms");
    },
  },
};
</script>
<style scoped>
</style>