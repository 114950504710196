<template>
  <div v-if="loading"><loading></loading></div>

  <div v-else>
    <div
      style="
        width: 60%;
        padding: 2%;
        display: inline-block;
        vertical-align: top;
      "
    >
      <div class="progress-container" style="height: 100px">
        <ul class="progressbar">
          <li :class="[isFormComplete('UserInfoForm') ? 'active' : '']">
            <span class="progress-indicator-text">Personal Information</span>
          </li>
          <li :class="[isFormComplete('PathOrRegionForm') ? 'active' : '']">
            <span class="progress-indicator-text">Path or Region</span>
          </li>
          <li :class="[isFormComplete('AnimalModelForm') ? 'active' : '']">
            <span class="progress-indicator-text">Animal Model</span>
          </li>
          <li :class="[isFormComplete('DomainAndMethodForm') ? 'active' : '']">
            <span class="progress-indicator-text">Domain and methodology</span>
          </li>
          <li
            :class="[isFormComplete('ImpactAndDirectionForm') ? 'active' : '']"
          >
            <span class="progress-indicator-text">Impact and direction</span>
          </li>
        </ul>
      </div>
      <div>
        <!-- Show different forms in order if all of them are not completed -->
        <div v-if="!allComplete" class="all-forms">
          <!-- Persona Information Form -->
          <user-info-form
            v-if="currentForm.name == 'UserInfoForm'"
            @on-complete="markFormCompleted('UserInfoForm')"
            @on-back="previousForm('UserInfoForm')"
          ></user-info-form>

          <!-- Select Path or Region -->
          <path-or-region-form
            v-if="currentForm.name == 'PathOrRegionForm'"
            @on-complete="markFormCompleted('PathOrRegionForm')"
            @on-back="previousForm('PathOrRegionForm')"
          ></path-or-region-form>

          <!-- Select Path or Region -->
          <animal-model-form
            v-if="currentForm.name == 'AnimalModelForm'"
            @on-complete="markFormCompleted('AnimalModelForm')"
            @on-back="previousForm('AnimalModelForm')"
          >
          </animal-model-form>

          <!-- Select domain of study and methodology -->
          <domain-and-method-form
            v-if="currentForm.name == 'DomainAndMethodForm'"
            @on-complete="markFormCompleted('DomainAndMethodForm')"
            @on-back="previousForm('DomainAndMethodForm')"
          >
          </domain-and-method-form>

          <!-- Impact and direction -->
          <impact-and-direction-form
            v-if="currentForm.name == 'ImpactAndDirectionForm'"
            @on-complete="markFormCompleted('ImpactAndDirectionForm')"
            @on-back="previousForm('ImpactAndDirectionForm')"
          >
          </impact-and-direction-form>
        </div>
        <div v-else>
          <form-submitted></form-submitted>
        </div>
      </div>
    </div>

    <div
      v-if="isDevEnv"
      style="width: 35%; display: inline-block"
      class="text-left"
    >
      <pre>{{ $store.state.form.pi }}</pre>
      <pre>{{ $store.state.form.study }}</pre>
    </div>

    <div class="mt-5 pt-3">
      <action-buttons></action-buttons>
    </div>
    <div class="mt-5 pt-3">
      <custom-footer></custom-footer>
    </div>
  </div>
</template>

<script>
import Loading from "../../common/Loading.vue";
import PathOrRegionForm from "./components/PathOrRegionForm.vue";
import AnimalModelForm from "./components/AnimalModelForm";
import UserInfoForm from "./components/UserInfoForm.vue";
import DomainAndMethodForm from "./components/DomainAndMethodForm";
import ImpactAndDirectionForm from "./components/ImpactAndDirectionForm.vue";
import FormSubmitted from "./components/FormSubmitted.vue";
import DevEnv from "../../../mixins/DevEnv";
import CustomFooter from "../../common/CustomFooter.vue";
import ActionButtons from "../../common/ActionButtons.vue";

/**
 * This component is built using freely available source code
 * on this page: http://kodhus.com/newsite/step-progress-bar-css-only/.
 *
 * This component contains all the different types of forms. It displays all those
 * forms in order depending on which step the user currently is.
 */

export default {
  name: "DataForm",
  mixins: [DevEnv],
  props: [],

  data() {
    return {
      // Will be false after it is done loading form metadata
      loading: true,
      /**
       * This array contains objects, each of which represents a form that the
       * user will be required to fill. Each field contains the following attributes
       * name - Name of the form
       * completed - true if the user has finished filling up this form
       * current - true if the user is currently filling this form
       *
       * */

      forms: [
        { name: "UserInfoForm", completed: false, current: true },
        { name: "PathOrRegionForm", completed: false, current: false },
        { name: "AnimalModelForm", completed: false, current: false },
        { name: "DomainAndMethodForm", completed: false, current: false },
        { name: "ImpactAndDirectionForm", completed: false, current: false },
      ],
    };
  },
  computed: {
    /**
     * This returns the form that the user is currently working on
     */
    currentForm() {
      for (const form of this.forms) {
        if (form.current) {
          return form;
        }
      }
      throw new Error(`Semantic error. No current form found`);
    },

    /**
     * This will return true if all the forms are completed
     */
    allComplete() {
      let allComplete = true;
      this.forms.forEach((form) => {
        if (!form.completed) {
          allComplete = false;
        }
      });
      return allComplete;
    },
  },
  methods: {
    /**
     * This method finds the form with the given name and marks it as completed.
     * If there is another form in the list, it will also make that form current
     * @param {String} formName - Name of the form which needs to be marked completed
     */
    markFormCompleted(formName) {
      this.forms.forEach((form, index) => {
        // Mark the form as completed
        if (form.name === formName) {
          form.completed = true;
          form.current = false;

          // If there is another form in the list, mark it as current
          if (index < this.forms.length - 1) {
            this.forms[index + 1].current = true;
          }
        }
      });
    },

    /**
     * This form marks previous form as current given the formName
     */
    previousForm(formName) {
      this.forms.forEach((form, index) => {
        if (form.name === formName) {
          if (index > 0) {
            this.forms[index - 1].current = true;
          }
        }
      });
    },

    /**
     * This method returns true, if the form with the given name is completed
     * @param {String} name
     * @return {Boolean} true if the given form is completed
     */
    isFormComplete(name) {
      for (const form of this.forms) {
        if (form.name === name && form.completed) return true;
      }
      return false;
    },
    async init() {
      await this.$store.dispatch("loadFormMetadata");
      await this.$store.dispatch("loadBrainHierarchy");
      this.loading = false;
    },
  },
  components: {
    UserInfoForm,
    PathOrRegionForm,
    AnimalModelForm,
    DomainAndMethodForm,
    ImpactAndDirectionForm,
    Loading,
    FormSubmitted,
    CustomFooter,
    ActionButtons,
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style-type: none;
  width: 20%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}
.progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: green;
}
.progressbar li.active:before {
  border-color: #55b776;
}
.progressbar li.active + li:after {
  background-color: #55b776;
}

@media (max-width: 550px) {
  .progress-indicator-text {
    font-size: 0px !important;
  }
}
</style>
