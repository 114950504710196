import Vuex from "vuex";
import Vue from "vue";
import form from "./modules/form";
import table from "./modules/table";
import FirebaseApi from "../api/FirebaseApi";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    table,
    form,
  },
  state: {
    formMetadata: [],
    brainHierarchy: null,
  },
  mutations: {
    formMetadata(state, metadata) {
      state.formMetadata = metadata;
    },
    brainHierarchy(state, brainHierarchy) {
      state.brainHierarchy = brainHierarchy;
    },
  },
  getters: {
    formMetadata(state) {
      return state.formMetadata;
    },
    brainHierarchy(state) {
      return state.brainHierarchy;
    },

    /**
     * Brain region
     * @param {*} state
     * @returns
     */
    targetFromMetadata(state) {
      return state.formMetadata.targetFrom;
    },

    availableBehavioralTests(state) {
      return state.formMetadata.behavioralTests;
    },
    availableStrains(state) {
      return state.formMetadata.strains;
    },
    availablePromoters(state) {
      return state.formMetadata.promoters;
    }
  },
  actions: {
    async loadFormMetadata({ commit }) {
      const formMetadata = await FirebaseApi.getFormMetadata();
      commit("formMetadata", formMetadata);
    },
    async loadBrainHierarchy({ commit }) {
      const brainHierarchy = await FirebaseApi.getBrainHierarchy();
      commit("brainHierarchy", brainHierarchy);
    },
  },
});
