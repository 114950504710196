/**
 * This mixin contains methods to show error and success messages
 * in the form of a toast
 */
export default {
    methods: {
        errorToast(title, message) {
            this.$bvToast.toast(
                message,
                {
                    title: title,
                    variant: "danger",
                    solid: true,
                }
            );
        },
        successToast(title, message) {
            this.$bvToast.toast(
                message,
                {
                    title: title,
                    variant: "success",
                    solid: true,
                }
            );
        }
    }
}