<template>
  <div class="mc-container">
    <div class="text-left mt-5 pd-4">
      <h1 class="mc-title">The team</h1>
    </div>

    <div class="mc-content text-left mt-5">
      <p>
        MouseCircuits.org was created in the Developmental Origins Of Resiliency
        (DOOR) lab at Columbia University by Dani Dumitriu (Principal
        Investigator) and Kristin Anderson (Postdoctoral Research Fellow), with
        the help of Allie Lipshutz (undergraduate research assistant) and and
        Santosh Paudel (website developer). Our lab’s overarching research
        questions is why some individuals are resilient to stress while others
        develop stress-induced pathology. We utilize stress-induced animal
        models such as social defeat stress to uncover the connectivity pattern
        of the brains of individuals who are susceptible and resilient to
        stress, particularly what patterns may exist prior to experiencing a
        stressor. We hope to help build a foundation of understanding
        resiliency, which can ultimately translate into the standardization of
        disease prevention.
      </p>
      <p>
        For more information,
        <a href="www.theDOORlab.com" style="color: #ff00ba">
          www.theDOORlab.com</a
        >
      </p>
    </div>

    <div class="mc-biography-container mt-4">
      <div
        class="p-4"
        style="max-width: 85%"
        v-for="biography in biographies"
        :key="biography.name"
      >
        <biography :biography="biography"></biography>
      </div>
    </div>

    <div class="mt-5">
      <action-buttons></action-buttons>
    </div>
    <div class="mt-5 pt-3">
      <custom-footer></custom-footer>
    </div>
  </div>
</template>
<script>
import Biography from "./components/Biography.vue";
import ActionButtons from "../../common/ActionButtons.vue";
import CustomFooter from "../../common/CustomFooter.vue";
export default {
  name: "Team",
  computed: {
    biographies() {
      return [
        {
          name: "Dani Dumitriu, MD, PhD",
          title: "Principal Investigator",
          description:
            "Dr. Dumitriu is trained as a general pediatrician, neuroscientist and pediatric environmental health scientist. She joined Columbia University as an Assistant Professor of Pediatrics (in Psychiatry) in November 2018. She dedicates 80% of her time to translational research into the neurobiological basis of resilience across species and modalities as the Principal Investigator of the DOOR (Developmental Origins of Resilience) lab at the New York State Psychiatric Institute, and 20% of her time to caring for newborns as a pediatric hospitalist in the Well Baby Nursery at the Morgan Stanley Children's Hospital.",
          image: "dani_dumitriu.png",
        },
        {
          name: "Kristin Anderson, PhD",
          title: "Postdoctoral Research Fellow",
          description:
            "Dr. Anderson is a postdoctoral research scientist in Dr. Dumitriu's lab. She joined the DOOR lab to pursue her research interest of understanding individual differences in response to stress. In her studies, she aims to uncover the trajectory of neural circuitry at multiple levels at timepoints before, during, and after stress. Kristin is also committed to science advocacy and community outreach. She is passionate about finding innovative ways to bridge science, the community, and policy at both the local and federal levels.",
          image: "kristin_anderson.png",
        },
        {
          name: "Allie Lipshutz",
          title: "Undergraduate Researcher",
          description:
            "Allie Lipshutz is an undergraduate senior at Barnard College, studying Neuroscience and Chemistry. Her research interests center around divergent neural circuits and pathophysiology in response to acute stress, as well as the mechanisms of cellular stress in cancer development. She intends to pursue a PhD in Biochemistry and looks forward to a career in academic science research.",
          image: "allie_lipshutz.png",
        },
        {
          name: "Santosh Paudel",
          title: "Software Developer",
          description:
            "Santosh is a software developer based in New Jersey. He grew up in Kathmandu, Nepal, and earned his Bachelor’s Degree in Computer Science from Ramapo College of New Jersey in 2019. He is passionate about coding and enjoys creating apps and designing software architecture to bring curiosities and ideas to life. He loves exploring new technologies and techniques and implementing them on real-life projects such as MouseCircuits.org. In his free time, he also likes hiking, traveling, and reading books.",
          image: "santosh_paudel.png",
        },
      ];
    },
  },
  components: {
    Biography,
    ActionButtons,
    CustomFooter,
  },
};
</script>
<style scoped>
.mc-container {
  margin-left: 5%;
  margin-right: 15%;
}
.mc-title {
  color: #ff00ba;
  font-weight: 700;
  font-size: 45px;
}
</style>