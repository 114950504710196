<template>
  <div class="m-5 p-5">
    <div>
      <b-icon
        icon="check-circle-fill"
        font-scale="8"
        variant="success"
      ></b-icon>
    </div>
    <h3 class="mt-5">Thank you!</h3>
    <h4 class="mt-3">You have successfully completed the form</h4>
    <p class="mt-4 text-muted">
      We will let you know via email once we approve your submission
    </p>

    <b-button
      class="mt-4"
      @click="$router.push({ name: 'Home' })"
      variant="light"
      >Home
    </b-button>
  </div>
</template>
<script>
/**
 * This component shows that the form is complete
 */
export default {
  name: "FormSubmitted",
};
</script>
<style lang="scss" scoped></style>
