<template>
  <div class="text-left">
    <b-form @submit="onSubmit">
      <!-- User's Full Name -->
      <b-form-group
        label="Full Name:"
        label-for="name-field-input"
        description="Full Name of the first author of the study"
      >
        <b-form-input
          id="name-field-input"
          v-model="fullName"
          :state="
            validation.fullName == null ? null : validation.fullName.status
          "
          placeholder="Enter name"
          required
        ></b-form-input>
        <b-form-invalid-feedback v-if="validation.fullName != null">
          {{ validation.fullName.msg }}
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- User's email address -->
      <b-form-group
        label="Email address:"
        label-for="email-field-input"
        description="Email address of the first author. 
        Please provide .edu, .org or .gov email if available"
      >
        <b-form-input
          id="email-field-input"
          v-model="email"
          type="email"
          placeholder="Enter email"
          :state="validation.email == null ? null : validation.email.status"
          required
        ></b-form-input>
        <b-form-invalid-feedback v-if="validation.email != null">
          {{ validation.email.msg }}
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- Name of the lab where this experiment was conducted -->
      <b-form-group
        label="Please enter the name of your Lab"
        label-for="lab-field-input"
        description="Name of the lab where this study was conducted"
      >
        <b-form-input
          id="lab-field-input"
          v-model="labName"
          placeholder="Lab Name"
          :state="validation.labName == null ? null : validation.labName.status"
          required
        ></b-form-input>
        <b-form-invalid-feedback v-if="validation.labName != null">{{
          validation.labName.msg
        }}</b-form-invalid-feedback>
      </b-form-group>

      <!-- Full name of the lab's Principal Investigator -->
      <b-form-group
        label="Name of the Principal Investigator"
        label-for="piName-field-input"
        description="Full name of the lab's Principal Investigator"
      >
        <b-form-input
          id="piName-field-input"
          v-model="piFullName"
          placeholder="PI's full name"
          :state="
            validation.piFullName == null ? null : validation.piFullName.status
          "
          required
        ></b-form-input>
        <b-form-invalid-feedback v-if="validation.piFullName != null">
          {{ validation.piFullName.msg }}
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- Email of the lab's principal investigator -->
      <b-form-group
        label="Email address of the Principal Investigator"
        label-for="piEmail-field-input"
        description="Please provide .edu, .org or .gov email if available"
      >
        <b-form-input
          id="piEmail-field-input"
          v-model="piEmail"
          type="email"
          placeholder="Enter PI's email"
          :state="validation.piEmail == null ? null : validation.piEmail.status"
          required
        ></b-form-input>
        <b-form-invalid-feedback v-if="validation.piEmail != null">{{
          validation.piEmail.msg
        }}</b-form-invalid-feedback>
      </b-form-group>

      <div class="d-flex justify-content-end mt-4">
        <b-button v-if="isDevEnv" variant="primary" @click="random()"
          >Random</b-button
        >
        <b-button size="lg" type="submit" class="mc-standard-btn"
          >Next</b-button
        >
      </div>
    </b-form>
  </div>
</template>
<script>
/**
 * This component is part of the main form. This contains
 * fields to allow user to provide their personal information
 */
import TextInputValidationMixin from "../../../../mixins/TextInputValidationMixin";
import EmailInputValidationMixin from "../../../../mixins/EmailInputValidationMixin";
import DevEnv from "../../../../mixins/DevEnv";
import FirebaseApi from "../../../../api/FirebaseApi";
export default {
  mixins: [TextInputValidationMixin, EmailInputValidationMixin, DevEnv],
  name: "UserInfoForm",
  props: {},
  data() {
    return {
      /**
       * This object contains the validation status of each fields above. Each attribute
       * corresponds to the validation status of that field in the form above. It will
       * initially contain null but once the user begins filling the form, the attributes
       * will contain actual values (most probably through the setters in computed properties)
       * When valid, each attribute will look like this
       * {status: true, msg: null}
       * When invalid, each attribute will look like this
       * {status: false, msg: "Error message for this field"}
       */
      validation: {
        fullName: null,
        email: null,
        labName: null,
        piFullName: null,
        piEmail: null,
      },
    };
  },
  computed: {
    fullName: {
      get: function () {
        return this.$store.getters["form/fullName"];
      },
      set: function (newVal) {
        const validation = this.validateTextInput(newVal);

        if (validation.status) {
          this.$store.commit("form/fullName", newVal);
        }

        this.validation.fullName = validation;
      },
    },
    email: {
      get: function () {
        return this.$store.getters["form/email"];
      },
      set: function (newVal) {
        const validation = this.validateEmail(newVal);

        if (validation.status) {
          this.$store.commit("form/email", newVal);
        }
        this.validation.email = validation;
      },
    },
    labName: {
      get: function () {
        return this.$store.getters["form/labName"];
      },
      set: function (newVal) {
        const validation = this.validateTextInput(newVal);

        if (validation.status) {
          this.$store.commit("form/labName", newVal);
        }
        this.validation.labName = validation;
      },
    },
    piFullName: {
      get: function () {
        return this.$store.getters["form/piFullName"];
      },
      set: function (newVal) {
        const validation = this.validateTextInput(newVal);

        if (validation.status) {
          this.$store.commit("form/piFullName", newVal);
        }
        this.validation.piFullName = validation;
      },
    },
    piEmail: {
      get: function () {
        return this.$store.getters["form/piEmail"];
      },
      set: function (newVal) {
        const validation = this.validateEmail(newVal);

        if (validation.status) {
          this.$store.commit("form/piEmail", newVal);
        }
        this.validation.piEmail = validation;
      },
    },

    /**
     * returns true if the form is valid
     */
    isFormValid() {
      let valid = true;
      for (const key in this.validation) {
        if (this.validation[key] != null && !this.validation[key].status) {
          valid = false;
          break;
        }
      }

      return valid;
    },
  },
  methods: {
    random() {
      this.fullName = "Su Amahle";
      this.email = "will.santosh@gmail.com";
      this.piFullName = "rw123v123p@toushizemi.com";
      this.piEmail = "santoshpaudel.dev@gmail.com";
      this.labName = "Palostrini lab";
    },
    async onSubmit(event) {
      event.preventDefault();

      await this.validatePiInfo(this.$store.getters["form/piEmail"]);

      if (this.isFormValid) {
        this.$emit("on-complete");
      }
    },

    /**
     * This function is called when the user clicks the "Next" button. It checks to see if the
     * principal investigator already exists in the database. If they do, it will
     * check to see if the information provided by the user matches the information in the database.
     */
    async validatePiInfo(email) {
      const pi = await FirebaseApi.getPiInfoFromEmail(email);

      if (pi != null) {
        const piFullNameFromForm = this.$store.getters["form/piFullName"];

        if (piFullNameFromForm.toLowerCase() != pi.fullName.toLowerCase()) {
          this.validation.piFullName = {
            status: false,
            msg: `The Full Name and email combination for the principal investigator is incorrect.
            Suggestion: ${pi.fullName}, ${pi.email} . If you think this is an error, please
            contact us at mousecircuits@gmail.com`,
          };
        }
      }
    },
  },
};
</script>
<style  scoped>
</style>
