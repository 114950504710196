<template>
  <div>
    <div class="d-flex justify-content-center">
      <div>
        <b-button
          size="lg"
          variant="outline-info"
          class="mb-2 mc-home-button"
          @click="searchConnectome"
        >
          <b-icon icon="search" aria-hidden="true"></b-icon> Search the
          Connectome
        </b-button>
      </div>
      <!-- A phantom div to occupy 56pt space -->
      <div style="width: 56pt"></div>
      <div>
        <b-button
          size="lg"
          variant="outline-info"
          class="mb-2 mc-home-button"
          @click="uploadResearch"
        >
          <b-icon icon="cloud-upload" aria-hidden="true"></b-icon> Upload
          Research
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ActionButtons",
  data: function () {
    return {};
  },
  methods: {
    searchConnectome() {
      this.$router.push("/connectome");
    },
    uploadResearch() {
      this.$router.push("/upload");
    },
  },
};
</script>
<style scoped>
.mc-home-button {
  background-color: #ff00ba;
  color: #ffffff;
  /* outline: none; */
  border: none;
}
</style>