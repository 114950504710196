
class FormOptions {
  static fields = [
    // Default value is "path". Possible values are "path", "region"
    { code: "studyType", name: "Study Type" },
    { code: "targetFrom", name: "Target From" },
    { code: "targetTo", name: "Target To" }, // It will be null if studyType is "region"
    { code: "animalModel", name: "Animal Model" },
    { code: "sex", name: "Sex" },
    { code: "age", name: "Age" },
    { code: "numAnimals", name: "Number of Animals Used" },
    { code: "strains", name: "Strains" },
    { code: "domain", name: "Domain" },
    { code: "behavioralTests", name: "behavioralTests" },
    { code: "methodology", name: "Methodology" },
    { code: "promoters", name: "Promoters" },
    { code: "virus", name: "Virus" },
    { code: "virusAmount", name: "Virus Amount" },
    { code: "targetRegionCoordinate", name: "Target Coordinate" },
    { code: "stimulPatternOrCnoDose", name: "Stimul Pattern or Cno Dose" },
    { code: "directionSummary", name: "Direction Summary" },
    { code: "reference", name: "Reference" },
    { code: "otherBehaviorRun", name: "Other Behavior Runs" },
    { code: "postHocConfirmInjection", name: "Post Hoc Confirmation Injection" },
    { code: "observeBeforePertub", name: "Observe Before Pertub" },
    { code: "impactOnFear", name: "Impact On Fear" },
    { code: "impactOnAnxiety", name: "Impact On Anxiety" },
    { code: "impactOnDepression", name: "Impact On Depression" },
    { code: "impactOnSocialReinforcement", name: "Impact On Social Reinforcement" },
    { code: "impactOnSociability", name: "Impact On Sociability" },
    { code: "impactOnSocialMemory", name: "Impact On Memory" },
    { code: "impactOnDominance", name: "Impact On Dominance" },
    { code: "impactOnAggression", name: "Impact On Aggression" },
    { code: "referenceLink", name: "Link to Reference" }
  ]

  static get formFields() {
    return FormOptions.fields;
  }

}

export default FormOptions;
