<template>
  <div class="text-left">
    <b-form @submit="onSubmit" class="text-left">
      <!-- Animal Model -->
      <b-form-group
        id="mc-animal-model-label"
        label="Animal model"
        label-for="mc-animal-model-select"
        description="What type of rodent model was utilized?"
      >
        <b-form-select
          id="mc-animal-model-select"
          v-model="animalModel"
          :options="animalModelOptions"
          required
        ></b-form-select>
      </b-form-group>

      <!-- Number of animals Used -->
      <div style="margin-bottom: 40px">
        <!-- Put additional margin after the label so that the slider doesn't overlap the label -->
        <p style="margin-bottom: 35px">
          {{ animalModelLabel }}
        </p>
        <vue-slider
          v-model="numAnimals"
          ref="mc-range-slider"
          :min="0"
          :max="100"
          :tooltip="'always'"
          :tooltip-placement="['top', 'bottom']"
          :enableCross="false"
        >
          <template v:slot="label">
            <!-- Only show label (error messages in this case), if the provided data
            is invalid -->
            <p
              class="mt-2"
              style="font-size: 80%; color: red"
              v-if="validation.numAnimals && !validation.numAnimals.status"
            >
              {{ validation.numAnimals.msg }}
            </p>
          </template>
        </vue-slider>
      </div>

      <div class="mb-3">
        <searchable-select
          id="mc-strain"
          label="Strain"
          :options="strainOptions"
          placeholder="Search or Select strains"
          description="What strain of mice or rats were used? Common strains are present 
          in the dropdown menu. Many are abbreviated or generalized such as 
          ChR2 for a genetic line with ChR2. The ‘other’ option exists to add your own entry. 
          Choose any combination of options. "
          @on-input="strains = $event"
          :selectMultiple="true"
          :initialValue="strains"
          :state="validation.strains == null ? null : validation.strains.status"
          :displayTextAndValue="false"
        >
          <!-- This contains error messages -->
          <template v-slot:invalid-feedback>
            <div
              v-if="validation.strains != null && !validation.strains.status"
              style="font-size: 80%; color: red"
            >
              {{ validation.strains.msg }}
            </div>
          </template>
        </searchable-select>
      </div>

      <!-- Sex -->
      <b-form-group
        id="mc-sex-label"
        label="Sex"
        label-for="mc-sex"
        description="What was/were the sex(es) of the animals used in the study? "
      >
        <b-form-select
          id="mc-sex"
          v-model="sex"
          :options="sexOptions"
          required
        ></b-form-select>
      </b-form-group>

      <!-- Age -->
      <b-form-group
        id="mc-age-label"
        label="Age"
        label-for="mc-age"
        description="What age were the animals? Choose the selection that best fits. "
      >
        <b-form-select
          id="mc-age"
          v-model="age"
          :options="ageOptions"
          required
        ></b-form-select>
      </b-form-group>

      <div class="d-flex justify-content-between mt-5">
        <back-button @click="$emit('on-back')"></back-button>
        <b-button v-if="isDevEnv" variant="primary" @click="random()"
          >Random</b-button
        >
        <b-button
          type="submit"
          size="lg"
          class="mc-standard-btn"
          variant="primary"
          >Next</b-button
        >
      </div>
    </b-form>
  </div>
</template>
<script>
/**
 * This form gives user the option to choose whether their study studies
 * a brain region or a path between two brain regions. It then, asks them
 * to select the proper path and a region
 */
import _ from "lodash";
import { mapGetters } from "vuex";
import SearchableSelect from "../../../common/SearchableSelect.vue";
import RangeInputValidationMixin from "../../../../mixins/RangeInputValidationMixin";
import BackButton from "../../../common/BackButton.vue";
import RandomInt from "../../../../mixins/RandomInt";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import DevEnv from "../../../../mixins/DevEnv";
export default {
  name: "AnimalModelForm",
  mixins: [RandomInt, RangeInputValidationMixin, DevEnv],
  data: function () {
    return {
      /**
       * This object contains the validation status of each fields above. Each attribute
       * corresponds to the validation status of that field in the form above. It will
       * initially contain null but once the user begins filling the form, the attributes
       * will contain actual values (most probably through the setters in computed properties)
       * When valid, each attribute will look like this
       * {status: true, msg: null}
       * When invalid, each attribute will look like this
       * {status: false, msg: "Error message for this field"}
       */
      validation: {
        animalModel: null,
        age: null,
        sex: null,
        numAnimals: null,
        strains: null,
      },

      // This will be set to true, when the validation fails
      // This way, we will know the user tried to
      // submit the form but failed
      failedSubmit: false,
    };
  },
  computed: {
    ...mapGetters(["availableStrains"]),
    /**
     * Returns All available brain regions from the database that
     * user can select. It will only return the leaf nodes of the hierarchical
     * structure of brain regions (as stored in the database). The user can only
     * select amont the leaf nodes
     */
    animalModelOptions() {
      return [
        { text: "Select an animal model", value: null },
        { text: "Mice", value: "mice" },
        { text: "Rat", value: "rat" },
        { text: "Mice and Rat", value: "mice and rat" },
      ];
    },

    animalModelLabel() {
      const animalModel = this.$store.state.form.study.animalModel;

      switch (animalModel) {
        case "rat":
          return "How many rats did you use?";
        case "mice":
          return "How many mice did you use?";
        default:
          return "How many animals did you use?";
      }
    },

    sexOptions() {
      return [
        { text: "Select sex", value: null },
        { text: "Male", value: "male" },
        { text: "Female", value: "female" },
        { text: "Male and Female", value: "male and female" },
      ];
    },
    ageOptions() {
      return [
        { text: "Select age", value: null },
        { text: "≤ 8 weeks", value: "≤ 8 weeks" },
        { text: "8-12 weeks", value: "8-12 weeks" },
        { text: "12-24 weeks", value: "12-24 weeks" },
        { text: "≥ 24 weeks", value: "≥ 24 weeks" },
      ];
    },

    strainOptions() {
      const options = _.chain(this.availableStrains)
        .map((strain) => {
          return { text: strain, value: strain };
        })
        .sortBy((option) => {
          return option.text;
        })
        .value();

      options.push({ text: "other", value: "other" });
      return options;
    },

    animalModel: {
      get: function () {
        return this.$store.state.form.study.animalModel;
      },
      set: function (newVal) {
        this.$store.commit("form/animalModel", newVal);
      },
    },
    age: {
      get: function () {
        return this.$store.state.form.study.age;
      },
      set: function (newVal) {
        this.$store.commit("form/age", newVal);
      },
    },
    sex: {
      get: function () {
        return this.$store.state.form.study.sex;
      },
      set: function (newVal) {
        this.$store.commit("form/sex", newVal);
      },
    },
    numAnimals: {
      get: function () {
        return this.$store.state.form.study.numAnimals;
      },
      set: function (newVal) {
        this.$store.commit("form/numAnimals", newVal);
      },
    },
    strains: {
      get: function () {
        return this.$store.state.form.study.strains;
      },
      set: function (newVal) {
        let val = newVal;
        if (typeof newVal === "string") {
          val = [newVal];
        }
        this.$store.commit("form/strains", val);
      },
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      // Make sure all the forms are valid before submission

      if (this.isFormValid()) {
        this.failedSubmit = false;
        this.$emit("on-complete");
      } else {
        this.failedSubmit = true;
      }
    },

    /**
     * This method validates all the forms and returns true if all
     * of them are valid. Otherwise, it returns false;
     */
    isFormValid() {
      let valid = true;
      this.validateForm();
      Object.keys(this.validation).forEach((key) => {
        const validation = this.validation[key];

        if (validation == null || !validation.status) {
          valid = false;
        }
      });

      return valid;
    },

    /**
     * This method validates whether the appropriate fields were selected
     */
    validateForm() {
      // This has built in validation on the component. We just need the user
      // to select one of the many available options. So, mark it as valid
      this.validation.animalModel = { status: true, msg: null };
      // This also has built in validation
      this.validation.age = { status: true, msg: null };

      // This also has built in validation
      this.validation.sex = { status: true, msg: null };

      if (this.strains == null || this.strains.length == 0) {
        this.validation.strains = {
          status: false,
          msg: "This field is required",
        };
      } else {
        this.validation.strains = {
          status: true,
          msg: null,
        };
      }

      // validate numAnimals using mixin
      this.validation.numAnimals = this.validateRangeInputInclusive(
        1,
        100,
        this.numAnimals
      );
    },

    random() {
      this.animalModel =
        this.animalModelOptions[
          this.randomInt(0, this.animalModelOptions.length)
        ].value;

      this.strains =
        this.strainOptions[this.randomInt(0, this.strainOptions.length)].value;

      this.sex =
        this.sexOptions[this.randomInt(0, this.sexOptions.length)].value;

      this.age =
        this.ageOptions[this.randomInt(0, this.ageOptions.length)].value;

      const randomList = [
        [0, 0],
        [0, 10],
        [10, 12],
      ];
      this.numAnimals = randomList[this.randomInt(0, randomList.length)];
    },
  },
  components: { SearchableSelect, BackButton, VueSlider },
};
</script>

<style>
</style>
