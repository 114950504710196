var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('div',[_c('loading')],1):_c('div',{staticClass:"mt-5 pt-3 mb-4"},[_c('div',[_c('button',{class:[
        'btn',
        'btn-outline-dark',
        'mr-3',
        _vm.currentActiveTab == 'region' ? 'mc-tab-active' : '',
      ],on:{"click":function($event){_vm.currentActiveTab = 'region'}}},[_c('b-icon',{staticClass:"mc-circle-fill",attrs:{"icon":"circle-fill","scale":"0.7"}}),_vm._v(" Region-Based ")],1),_c('button',{class:[
        'btn',
        'btn-outline-dark',
        'ml-3',
        _vm.currentActiveTab == 'path' ? 'mc-tab-active' : '',
      ],on:{"click":function($event){_vm.currentActiveTab = 'path'}}},[_c('b-icon',{staticClass:"mc-circle-fill",attrs:{"icon":"circle-fill","scale":"0.7"}}),_vm._v(" Pathway-Based ")],1)]),_c('div',{key:this.currentActiveTab,staticClass:"mt-5 mb-5"},[_vm._l((_vm.charts),function(chart){return _c('div',{key:chart,staticClass:"mt-2"},[_c('div',{staticClass:"d-flex justify-content-center mb-5"},[_c('div',{staticStyle:{"width":"40%"}},[_c('chart-renderer',{attrs:{"chartConfiguration":_vm.buildChartConfig(chart)}})],1)])])}),_c('div',{staticClass:"d-flex justify-content-center mb-5 mt-2"},[_c('div',{staticStyle:{"width":"40%"}},[_c('p',{staticStyle:{"font-size":"35px","font-weight":"bold","color":"#ff00ba"}},[_vm._v(" Number of Behavior ")]),_c('div',{staticClass:"d-flex justify-content-around pt-5 pb-5 jumbotron"},[_c('div',[_c('p',[_vm._v("Total for low range")]),_c('span',{staticClass:"badge badge-light pl-3 pr-3"},[_c('div',[_c('h1',[_vm._v(" "+_vm._s(_vm.sumRangeNumAnimals.low)+" ")])])])]),_c('div',[_c('p',[_vm._v("Total for high range")]),_c('div',[_c('h1',[_vm._v(_vm._s(_vm.sumRangeNumAnimals.high))])])])])])])],2),_c('div',{staticStyle:{"height":"40px"}}),_c('div',{staticClass:"mb-5 mt-5"},[_c('action-buttons')],1),_c('div',[_c('custom-footer')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }