<template>
  <div>
    <canvas ref="w-chart"></canvas>
  </div>
</template>
<script>
import { Chart, registerables } from "chart.js";

export default {
  name: "Chart-Renderer",
  props: {
    chartConfiguration: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      chart: Chart,
    };
  },
  computed: {},
  watch: {},
  methods: {
    drawCharts() {
      const context = this.$refs["w-chart"].getContext("2d");
      // Getting started: https://www.chartjs.org/docs/latest/getting-started/integration.html
      Chart.register(...registerables);
      this.chart = new Chart(context, this.chartConfiguration);
    },
  },
  components: {},
  mounted() {
    this.drawCharts();
  },
  destroyed() {},
};
</script>
<style scoped></style>