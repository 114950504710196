<template>
  <div id="mc-floating-boxu3" class="pb-3" :style="style" :key="key">
    <div class="card text-left">
      <div class="card-header p-1">
        <div class="d-flex justify-content-between">
          <!-- TODO: Separate the divs  and write proper doc -->
          <div>
            <div v-if="isPathwaysData">
              Path: {{ source.code }} - {{ target.code }}
            </div>
            <div v-else>Region: {{ source.code }}</div>
            <div>
              <small>Number of studies: {{ studies.length }}</small>
            </div>
          </div>
          <div></div>
          <div class="mc-close-float-box">
            <span @click="onClose">×</span>
          </div>
        </div>
      </div>
      <div class="card-body mc-card-body p-0 m-0">
        <!-- <b-icon-plus scale="1.5" :style="{position: 'absolute'}"></b-icon-plus> -->

        <div v-if="studies.length > 0">
          <div
            v-for="(study, index) in studies"
            :key="study.id"
            class="mc-clickable pt-2 pl-1 pr-1"
            @click="onStudyClicked(study)"
          >
            <div v-if="isPathwaysData">
              <p class="p-0 m-0">
                <small
                  ><strong>Target From:</strong> {{ study.targetFrom }}</small
                >
              </p>
              <p class="p-0 m-0">
                <small><strong>Target To: </strong>{{ study.targetTo }}</small>
              </p>
            </div>
            <div v-else>
              <p class="p-0 m-0">
                <strong>Region: </strong>{{ study.targetFrom }}
              </p>
            </div>
            <p class="p-0 m-0">
              <small><strong>Domain:</strong> {{ study.domain }}</small>
            </p>
            <p class="p-0 m-0">
              <small
                ><strong>Reference:</strong>

                {{ study.reference }}
              </small>
            </p>
            <p class="p-0 m-0">
              <small
                ><strong>Reference Link:</strong>
                <span
                  v-if="
                    study.referenceLink == null || study.referenceLink == ''
                  "
                >
                  N/A
                </span>
                <a
                  v-else
                  :href="study.referenceLink"
                  @click.stop
                  target="_blank"
                >
                  Click Here</a
                >
              </small>
            </p>

            <!-- Don't show the horizontal line after the last study -->
            <hr v-if="index < studies.length - 1" class="m-0 p-0" />
          </div>
        </div>
        <div v-else>There are no studies on this region yet</div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * This componet is used to display the list of studies
 */
export default {
  name: "FloatingInputBox",
  props: {
    coords: {
      type: Object,
      required: true,
    },
    // TODO: document this and "target"
    source: {
      type: Object,
      required: true,
    },
    target: {
      type: Object,
    },
    studies: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      userInput: "",
      key: 0,
    };
  },
  computed: {
    //   TODO: Explain this
    isPathwaysData() {
      if (this.target == null) return false;

      return true;
    },

    displayProperty() {
      return this.show === true ? "block" : "none";
    },
    style() {
      return {
        position: "absolute",
        left: `${this.coords.x}px`,
        top: `${this.coords.y}px`,
        width: "16rem",
      };
    },
  },
  methods: {
    onInput: function () {
      if (this.userInput.trim() !== "") {
        this.$emit("on-user-input", this.userInput);
      }
      this.userInput = "";
    },
    onClose: function () {
      this.$emit("on-close", { tag: this.tag });
    },
    onStudyClicked: function (study) {
      let studyRoute = this.$router.resolve({
        name: "Study",
        params: { id: study.id },
      });
      window.open(studyRoute.href, "_blank");
    },
  },
};
</script>
<style scoped>
#mc-floating-boxu3 {
  z-index: 1000;
  position: absolute;
}

.mc-card-body {
  max-height: 500px !important;
  overflow-y: scroll;
}
.mc-close-float-box span {
  cursor: pointer;
  color: #5d625b;
  transition: color 0.3s;
  font-size: 1.6rem;
  position: absolute;
  top: -8px;
  right: 4px;
}
.mc-close-float-box span :hover {
  /* font-size: 1.4em; */
  transform: scale(2);
  color: #2d2f2c;
}

.mc-clickable {
  cursor: pointer;
}

.mc-clickable:hover {
  background-color: rgba(0, 23, 114, 0.03);
}
</style>
