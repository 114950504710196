<template>
  <div class="container-fluid mt-4">
    <div class="d-flex justify-content-between">
      <!-- Image button to allow xlsx download -->
      <div></div>
      <b-button
        @click="exportXlsx"
        variant="light"
        class="mc-export-btn p-1 mr-5"
      >
        <!-- When exporting is false, show the default file-earmark icon in the export button
        But when the file is being generated (i.e. exporting is set to true), show the spinner -->
        <div
          v-if="exporting"
          class="spinner-border spinner-border-sm"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <b-icon v-else icon="file-earmark-spreadsheet"> </b-icon>
        <span>&nbsp;Export</span></b-button
      >
    </div>
    <div>
      <b-tabs @input="onTabSelected" content-class="mt-3">
        <!-- This tabs content will always be mounted -->
        <b-tab title="Path" lazy><table-component></table-component></b-tab>

        <!-- This tabs content will not be mounted until the tab is shown -->
        <!-- and will be un-mounted when hidden -->
        <b-tab title="Region" lazy
          ><table-component dataType="region"></table-component
        ></b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import TableComponent from "./components/TableComponent.vue";
import XlsxExport from "../../../data/XlsxExport";
import { BIcon } from "bootstrap-vue";
export default {
  name: "TableTabs",
  data: function () {
    return {
      loading: true,

      /**
       * When the user clicks Export button, this attribute should be set to true.
       * When the exporting is done, this should be set to false
       */
      exporting: false,

      // This is used to decide which tab to open at any given point
      activeTab: "path",
    };
  },
  computed: {},
  methods: {
    /**
     * This is called every time the user selects a tab
     * @param {number} index Index of the tab
     */
    onTabSelected(index) {
      switch (index) {
        case 0:
          this.$store.dispatch("table/setTableType", "path");
          break;
        case 1:
          this.$store.dispatch("table/setTableType", "region");
          break;
      }
    },

    async exportXlsx() {
      try {
        this.exporting = true;

        await XlsxExport.export();

        this.exporting = false;
      } catch (err) {
        console.error("Unable to download file");
        console.error(err);
      }
    },
  },
  mounted() {},
  components: {
    TableComponent,
    BIcon,
  },
};
</script>
<style scoped>
.mc-export-btn img {
  width: 30px;
  height: auto;
}
</style>
