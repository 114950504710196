var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left"},[_c('b-form',{staticClass:"text-left",on:{"submit":_vm.onSubmit}},[_c('b-form-group',{attrs:{"label":"Select Input Type","label-for":"mc-studyType-select","description":"Was a path or region studied? Path indicates that the study was conducted on a \n      pathway between two brain regions. Region indicates that the study was conducted on a \n      single brain region"}},[_c('b-form-select',{attrs:{"id":"mc-studyType-select","options":[
          { text: 'Path', value: 'path' },
          { text: 'Region', value: 'region' },
        ],"required":""},model:{value:(_vm.studyType),callback:function ($$v) {_vm.studyType=$$v},expression:"studyType"}})],1),_c('b-row',[_c('b-col',{attrs:{"cols":_vm.studyType == 'path' ? '6' : '12'}},[_c('searchable-select',{attrs:{"id":"mc-target-from","label":_vm.studyType == 'region' ? 'Brain Region' : 'From',"options":_vm.brainRegionOptions,"placeholder":"Search or Select a brain region","state":_vm.validation.targetFrom == null
              ? null
              : _vm.validation.targetFrom.status,"initialValue":_vm.targetFrom,"description":_vm.targetFromDescription},on:{"on-input":function($event){_vm.targetFrom = $event}},scopedSlots:_vm._u([{key:"invalid-feedback",fn:function(){return [(
                _vm.validation.targetFrom != null && !_vm.validation.targetFrom.status
              )?_c('div',{staticStyle:{"font-size":"80%","color":"red"}},[_vm._v(" "+_vm._s(_vm.validation.targetFrom.msg)+" ")]):_vm._e()]},proxy:true}])})],1),(_vm.studyType == 'path')?_c('b-col',{attrs:{"cols":"6"}},[_c('searchable-select',{key:"target-to",attrs:{"id":"mc-target-to","label":"To","options":_vm.brainRegionOptions,"placeholder":"Search or Select a brain region","state":_vm.validation.targetTo == null ? null : _vm.validation.targetTo.status,"initialValue":_vm.targetTo,"description":"What is the downstream brain region of the pathway studied? \n          Regions follow the Allen Brain Atlas nomenclature. \n          (Note: if there was more than one brain region studied, upload each as a separate entry)."},on:{"on-input":function($event){_vm.targetTo = $event}},scopedSlots:_vm._u([{key:"invalid-feedback",fn:function(){return [(
                _vm.validation.targetTo != null && !_vm.validation.targetTo.status
              )?_c('div',{staticStyle:{"font-size":"80%","color":"red"}},[_vm._v(" "+_vm._s(_vm.validation.targetTo.msg)+" ")]):_vm._e()]},proxy:true}],null,false,1227873887)})],1):_vm._e()],1),_c('div',{staticClass:"d-flex justify-content-between mt-5"},[_c('back-button',{on:{"click":function($event){return _vm.$emit('on-back')}}}),(_vm.isDevEnv)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.random()}}},[_vm._v("Random")]):_vm._e(),_c('b-button',{staticClass:"mc-standard-btn",attrs:{"size":"lg","type":"submit","variant":"primary"}},[_vm._v("Next")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }