<template>
  <div class="mc-home container-fluid">
    <div style="margin-top: 100px" class="text-center">
      <b-row>
        <b-col cols="2"></b-col>
        <b-col cols="8"
          ><div class="mc-body-title text-center">
            <h1>
              <strong>
                An open source sharing platform for rodent chemogenetic and
                optogenetic circuit investigation</strong
              >
            </h1>
          </div>
        </b-col>
        <b-col cols="2"></b-col>
      </b-row>
    </div>

    <div style="margin-top: 110px">
      <action-buttons></action-buttons>
    </div>
  </div>
</template>

<script>
import ActionButtons from "../common/ActionButtons.vue";

export default {
  name: "Home",
  methods: {},
  components: {
    ActionButtons,
  },
};
</script>
<style scoped>
.mc-body-title {
  color: #ffffff;
}

@media (max-width: 544px) {
  h1 {
    font-size: 30px;
  } /*1rem = 16px*/
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  h1 {
    font-size: 40px;
  } /*1rem = 16px*/
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  h1 {
    font-size: 60px;
  } /*1rem = 16px*/
}
</style>
