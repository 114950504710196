export default {
  methods: {
    /**
     * This method performs a regex test on the given parameter to determine if it is a
     * valid email address
     * @param {string} email
     * @return {Object} an object that contains that contains two fields: status, msg
     * E.g. {status: false, msg: "This field cannot be empty"}
     *
     * status: status is a boolean indicator that is set to true if the input is valid (false otherwise)
     * msg: If the status is false, msg contains explanation. Otherwise, it will be null
     */
    validateEmail(email) {
      if (email == null)
        return { status: false, msg: "This field is required" };
      let re = /(.+)@(.+){2,}\.(.+){2,}/;
      const valid = re.test(email.toLowerCase());

      if (valid) {
        return { status: true, msg: null };
      } else {
        return { status: false, msg: "Invalid email provided" };
      }
    },
  },
};
