<template>
  <div class="mc-container">
    <div class="text-left mt-5 pd-4">
      <h1 class="mc-title">The method</h1>
    </div>
    <b-row class="text-left mt-5">
      <b-col cols="4">
        <p style="font-size: 1.1rem">
          <strong
            >As a tool in the neuroscience tool box, MouseCircuits.org serves as
            a location to:</strong
          >
        </p>
        <div>
          <ul class="mc-list">
            <li>
              <span
                >House data relevant for reproducibility and transparency</span
              >
            </li>
            <li><span>Provide a whole-brain connectome overview</span></li>
            <li>
              <span
                >Provide landscape overviews to highlight progress and
                gaps</span
              >
            </li>
            <li><span>Serve as a data sharing platform</span></li>
            <li><span>Inform new studies or analyses</span></li>
          </ul>
        </div>
      </b-col>
      <b-col cols="8">
        <p>
          MouseCircuits.org is a tool for neuroscientists that use circuit
          dissection tools to undercover the neural underpinnings of behavior.
          Currently, MouseCircuits.org includes data sources from original
          manuscripts in which optogenetics or chemogenetics were used to
          dissect behaviors related to fear-like, depressive-like or
          anxiety-like behaviors in rodents. A key feature of MouseCircuits.org
          is hosting a whole brain vantage point of all the studies in the site
          database. In the map, the size of nodes correlates to the number of
          studies that have studied that region. Similarly, the opacity of the
          edge between two regions correlates to the number of studies that
          looked at that pathway. Red edges indicate this pathway increased
          affective-like behavior while blue indicates this pathway decreased
          affective-like behavior. A black edge indicates that pathways has been
          implicated in both increasing and decreasing affective-like states.
          Dropdown menus can be used to filter the map. The region and pathway
          tabs house key experimental details and outcomes in addition to
          landscape overviews that give users a bird’s eye view of the field to
          highlight trends, progress, and gaps that require more research. Users
          can search experimental details for specific information as well to
          answer many question such as: how has a specific virus been used
          before, what has been the stimulation pattern used in a brain region,
          or what brain regions have been studied in relation to a specific
          behavior.
        </p>
      </b-col>
    </b-row>

    <div class="mt-5">
      <div class="mt-5 pt-5">
        <action-buttons></action-buttons>
      </div>
      <div class="mt-5 pt-3">
        <custom-footer></custom-footer>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButtons from "../../common/ActionButtons.vue";
import CustomFooter from "../../common/CustomFooter.vue";
export default {
  name: "Method",
  components: {
    ActionButtons,
    CustomFooter,
  },
};
</script>
<style scoped>
.mc-list li {
  font-size: 1.5rem;
  color: #ff008a;
}

.mc-list span {
  font-size: 1rem !important;
  color: #605e5e;
}

.mc-container {
  margin-left: 5%;
  margin-right: 10%;
}
.mc-title {
  color: #ff00ba;
  font-weight: 700;
  font-size: 45px;
}
</style>