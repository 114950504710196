export default {
    computed: {
        /**
         * Return trus for development environment
  
         * @returns
         */
        isDevEnv() {

            return process.env.NODE_ENV === 'development';
        },
    },
};
