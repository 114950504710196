<template>
  <div>
    <hierarchical-edge-binding></hierarchical-edge-binding>
  </div>
</template>
<script>
import HierarchicalEdgeBinding from "./HierarchicalEdgeBunding.vue";

export default {
  name: "Connectome",
  components: {
    HierarchicalEdgeBinding,
  },
};
</script>
<style scoped></style>
